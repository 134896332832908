// // Projects
// Fetch
export const FETCH_PROJECTS = 'FETCH_PROJECTS'
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS'
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROEJCTS_FAILURE'
// Fetch one project
export const FETCH_ONE_PROJECT = 'FETCH_ONE_PROJECT'
export const FETCH_ONE_PROJECT_SUCCESS = 'FETCH_ONE_PROJECT_SUCCESS'
export const FETCH_ONE_PROJECT_FAILURE = 'FETCH_ONE_PROJECT_FAILURE'
// Add
export const ADD_PROJECT = 'ADD_PROJECT'
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS'
export const ADD_PROJECT_FAILURE = 'ADD_PROJECT_FAILURE'
// Edit
export const EDIT_PROJECT = 'EDIT_PROJECT'
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS'
export const EDIT_PROJECT_FAILURE = 'EDIT_PROJECT_FAILURE'
// Delete
export const DELETE_PROJECT = 'DELETE_PROJECT'
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS'
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE'
// Delete screen
export const DELETE_SCREEN = 'DELETE_SCREEN'
export const DELETE_SCREEN_SUCCESS = 'DELETE_SCREEN_SUCCESS'
export const DELETE_SCREEN_FAILURE = 'DELETE_SCREEN_FAILURE'

// // Components
// Add
export const ADD_COMPONENT = 'ADD_COMPONENT'
export const ADD_COMPONENT_SUCCESS = 'ADD_COMPONENT_SUCCESS'
export const ADD_COMPONENT_FAILURE = 'ADD_COMPONENT_FAILURE'
// Fetch
export const FETCH_COMPONENTS = 'FETCH_COMPONENT'
export const FETCH_COMPONENTS_SUCCESS = 'FETCH_COMPONENT_SUCCESS'
export const FETCH_COMPONENTS_FAILURE = 'FETCH_COMPONENT_FAILURE'
// Add child
export const ADD_CHILD = 'ADD_CHILD'
export const ADD_CHILD_SUCCESS = 'ADD_CHILD_SUCCESS'
export const ADD_CHILD_FAILURE = 'ADD_CHILD_FAILURE'
// Drop child
export const DROP_CHILD = 'DROP_CHILD'
export const DROP_CHILD_SUCCESS = 'DROP_CHILD_SUCCESS'
export const DROP_CHILD_FAILURE = 'DROP_CHILD_FAILURE'
// Change parent
export const CHANGE_PARENT = 'CHANGE_PARENT'
export const CHANGE_PARENT_SUCCESS = 'CHANGE_PARENT_SUCCESS'
export const CHANGE_PARENT_FAILURE = 'CHANGE_PARENT_FAILURE'
// Rename component
export const RENAME_COMPONENT = 'RENAME_COMPONENT'
export const RENAME_COMPONENT_SUCCESS = 'RENAME_COMPONENT_SUCCESS'
export const RENAME_COMPONENT_FAILURE = 'RENAME_COMPONENT_FAILURE'
// Change property
export const CHANGE_PROPERTY = 'CHANGE_PROPERTY'
export const CHANGE_PROPERTY_SUCCESS = 'CHANGE_PROPERTY_SUCCESS'
export const CHANGE_PROPERTY_FAILURE = 'CHANGE_PROPERTY_FAILURE'
// Change position
export const CHANGE_POSITION = 'CHANGE_POSITION'
export const CHANGE_POSITION_SUCCESS = 'CHANGE_POSITION_SUCCESS'
export const CHANGE_POSITION_FAILURE = 'CHANGE_POSITION_FAILURE'
// Update XML
export const UPDATE_SCREEN_XML = 'UPDATE_SCREEN_XML'
export const UPDATE_SCREEN_XML_SUCCESS = 'UPDATE_SCREEN_XML_SUCCESS'
export const UPDATE_SCREEN_XML_FAILURE = 'UPDATE_SCREEN_XML_FAILURE'
// Update Code
export const UPDATE_SCREEN_CODE = 'UPDATE_SCREEN_CODE'
export const UPDATE_SCREEN_CODE_SUCCESS = 'UPDATE_SCREEN_CODE_SUCCESS'
export const UPDATE_SCREEN_CODE_FAILURE = 'UPDATE_SCREEN_CODE_FAILURE'
// Upload image
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE'
// Change image
export const CHANGE_IMAGE = 'CHANGE_IMAGE'
export const CHANGE_IMAGE_SUCCESS = 'CHANGE_IMAGE_SUCCESS'
export const CHANGE_IMAGE_FAILURE = 'CHANGE_IMAGE_FAILURE'
// Duplicate components
export const DUPLICATE_COMPONENT = 'DUPLICATE_COMPONENT'
export const DUPLICATE_COMPONENT_SUCCESS = 'DUPLICATE_COMPONENT_SUCCESS'
export const DUPLICATE_COMPONENT_FAILURE = 'DUPLICATE_COMPONENT_FAILURE'
// Delete
export const DELETE_COMPONENT = 'DELETE_COMPONENT'
export const DELETE_COMPONENT_SUCCESS = 'DELETE_COMPONENT_SUCCESS'
export const DELETE_COMPONENT_FAILURE = 'DELETE_COMPONENT_FAILURE'
// Delete chilren
export const DELETE_CHILDREN = 'DELETE_CHILDREN'
export const DELETE_CHILDREN_SUCCESS = 'DELETE_CHILDREN_SUCCESS'
export const DELETE_CHILDREN_FAILURE = 'DELETE_CHILDREN_FAILURE'