import React from 'react';
import { Route } from 'react-router-dom';
import Cookie from 'js-cookie';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (!!Cookie.get('userId')) {
                    return (
                        <Component {...props} />
                    );
                } else {
                    window.location.replace(process.env.REACT_APP_AUTH + '?redirectURL=' + window.location.href)
                    return null
                }
            }}
        />
    )
}

export default ProtectedRoute