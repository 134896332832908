import React, { Fragment } from 'react';
import Cookie from 'js-cookie'
// Redux
import { connect } from 'react-redux';
import * as actions from '../../redux/actions'
// styles
import styles from './ProjectList.module.css'
// React components
import { SingleProject } from '../../components/singleProject/singleProject'
import { leftSideNav as LeftSideNav } from '../../components/navigation/leftSideNav/leftSideNav'
import { Modal } from '../../components/ui/modal/modal'
// static
import addProjectIcon from '../../static/images/addProjectBlue.svg'
import addTableIcon from '../../static/images/addTableBlue.svg'

class ProjectList extends React.Component {

    constructor(props) {
        super(props)
        document.title = 'ITK - My Projects'
        this.state = {
            // project
            search: "",
            addProject: false,
            project: {
                name: null,
                description: null,
                pid: null,
            },
            newProject: {
                name: null,
                description: null,
                userid: null,
                active: true
            },
            showProject: true,
            // firebase tables
            //// add table
            addTable: false,
            newTable: {
                name: '',
                description: '',
                column: [
                    {
                        number: 1,
                        columnName: ''
                    }
                ],
            },
            uniqueTableName: null,
            //// edit table
            table: {},
            mydb: [],
        }
        this.userid = null
    }

    componentDidMount() {
        this.userid = Cookie.get('userId')
        this.props.fetchProjects(this.userid);
        let mydb = this.props.firebase.db.ref('/' + this.userid + '/')
        mydb.on('value', snap => {
            let tables = snap.val() || {}
            let userTables = []
            Object.keys(tables).map(ut => {
                let columnDataSingle = {}
                Object.keys(tables[ut].columnData).map(colid => columnDataSingle[colid] = { ...tables[ut].columnData[colid], edited: false, removed: false })
                userTables.push({
                    ...tables[ut],
                    tableNameEdited: false,
                    tableDescriptionEdited: false,
                    columnData: columnDataSingle,
                })
                return null
            })
            this.setState({
                mydb: userTables
            })
        })
    }

    toggleScreen = (bool) => {
        this.setState({ showProject: bool })
    }

    onDeleteHandler = (projectId) => {
        if (window.confirm('Are you sure you want to delete this project?')) {
            this.props.deleteProject(projectId)
        }
    }

    onSelectHandler = (projectId) => {
        this.state.project.pid === null
            ? this.setState({
                project: {
                    pid: projectId,
                    name: this.props.projects[projectId].name,
                    description: this.props.projects[projectId].description,
                }
            })
            : this.setState({
                project: {
                    pid: null,
                    name: null,
                    description: null,
                }
            })
    }

    onEditHandler = (e) => {
        this.setState({
            project: {
                ...this.state.project,
                [e.target.name]: e.target.value
            }
        })
    }

    onEditSubmitHandler = () => {
        this.props.editProject(this.state.project)
        this.onSelectHandler()
    }

    onAddProjectClick = () => {
        this.setState(prevState => {
            return {
                addProject: !prevState.addProject
            }
        })
    }

    onNewProjectHandler = (e) => {
        let newProject = this.state.newProject
        newProject[e.target.name] = e.target.value
        this.setState({
            newProject: newProject
        })
    }

    onAddProjectSubmit = () => {
        let newProject = this.state.newProject
        newProject.userid = this.userid
        this.props.addProject(newProject)
        this.onAddProjectClick()
    }

    onSearch = (e) => {
        this.setState({ search: e.target.value })
    }

    onTableCreateClick = () => {
        this.setState(prevState => {
            return {
                addTable: !prevState.addTable,
                newTable: {
                    name: '',
                    description: '',
                    column: [
                        {
                            number: 1,
                            columnName: ''
                        }
                    ],
                },
            }
        })
    }

    onNewTablePropertyChange = (e) => {
        this.setState({
            newTable: {
                ...this.state.newTable,
                [e.target.name]: e.target.value
            },
            uniqueTableName: null,
        })
    }

    checkNewTableUniqueName = () => {
        let newTableName = this.state.newTable.name
        if ((this.state.mydb.filter(t => t.name === newTableName)).length !== 0) {
            this.setState({
                uniqueTableName: 'Table with such name already exists.'
            })
        }
    }

    onNewTableColumnChange = (e) => {
        this.setState({
            newTable: {
                ...this.state.newTable,
                column: this.state.newTable.column.map(col => {
                    if (col.number === parseInt(e.target.name)) {
                        return {
                            ...col,
                            columnName: e.target.value
                        }
                    }
                    return col
                })
            }
        })
    }

    onNewTableColumnAdd = (number) => {
        let newColumn = {
            number: number + 1,
            columnName: ''
        }
        let column = Array.from(this.state.newTable.column)
        column.map(col => {
            if (col.number >= number + 1) {
                return {
                    ...col,
                    number: ++col.number,
                }
            }
            return col
        })
        column.splice(number, 0, newColumn)
        this.setState({
            newTable: {
                ...this.state.newTable,
                column: column,
            }
        })
    }

    onNewTableColumnDelete = (number) => {
        let columnOnDelete = Array.from(this.state.newTable.column)
        columnOnDelete = columnOnDelete.filter(col => col.number !== number)
        columnOnDelete.map(col => {
            if (col.number > number) {
                return {
                    ...col,
                    number: --col.number
                }
            }
            return col
        })
        this.setState({
            newTable: {
                ...this.state.newTable,
                column: columnOnDelete
            }
        })
    }

    onTableCreate = () => {
        this.props.firebase.createOneTable(this.userid, this.state.newTable)
        this.onTableCreateClick()
    }

    onTableEditClick = (tableid) => {
        if (Object.keys(this.state.table).length === 0) {
            this.setState({
                table: this.state.mydb.find(t => t.id === tableid)
            })
        } else {
            this.setState({
                table: {}
            })
        }
    }

    onEditTableNameChange = e => {
        if (this.state.mydb.filter(t => t.name === e.target.value).length !== 0) {
            this.setState({
                table: {
                    ...this.state.table,
                    tableNameEdited: true,
                    name: e.target.value
                },
                uniqueTableName: 'Table with such name already exists.'
            })
        }
        else {
            this.setState({
                table: {
                    ...this.state.table,
                    tableNameEdited: true,
                    name: e.target.value
                },
                uniqueTableName: null,
            })
        }
    }

    onEditTableNameChangeSave = () => {
        this.props.firebase.updateTableName(this.userid, this.state.table.id, this.state.table.name)
        this.setState({
            table: {
                ...this.state.table,
                tableNameEdited: false,
            }
        })
    }

    onEditTableNameChangeCancel = () => {
        let prevTableName = this.state.mydb.find(t => t.id === this.state.table.id).name
        this.setState({
            table: {
                ...this.state.table,
                name: prevTableName,
                tableNameEdited: false,
            }
        })
    }

    onEditTableDescriptionChange = e => {
        this.setState({
            table: {
                ...this.state.table,
                tableDescriptionEdited: true,
                description: e.target.value
            }
        })
    }

    onEditTableDescriptionChangeSave = () => {
        this.props.firebase.updateTableDescription(this.userid, this.state.table.id, this.state.table.description)
        this.setState({
            table: {
                ...this.state.table,
                tableDescriptionEdited: false,
            }
        })
    }

    onEditTableDescriptionChangeCancel = () => {
        let prevTableName = this.state.mydb.find(t => t.id === this.state.table.id).description
        this.setState({
            table: {
                ...this.state.table,
                description: prevTableName,
                tableDescriptionEdited: false,
            }
        })
    }

    onEditTableColumnAdd = index => {
        let randomNumber = Math.ceil(Math.random() * 1000)
        while ('newColumn' + randomNumber in this.state.table.columnData) {
            randomNumber = Math.ceil(Math.random() * 1000)
        }
        let columnOrder = Array.from(this.state.table.columnOrder)
        columnOrder.splice(index, 0, 'newColumn' + randomNumber)
        this.setState({
            table: {
                ...this.state.table,
                columnData: {
                    ...this.state.table.columnData,
                    ['newColumn' + randomNumber]: {
                        name: '',
                        added: true,
                        edited: false,
                        removed: false,
                    }
                },
                columnOrder: columnOrder,
            }
        })
    }

    onEditTableColumnAddSave = colId => {
        let index = this.state.table.columnOrder.indexOf(colId)
        let colName = this.state.table.columnData[colId].name
        this.props.firebase.addColumn(this.userid, this.state.table.id, index, colName).then(id => {
            let newColumnData = Object.assign(this.state.table.columnData)
            newColumnData[id] = newColumnData[colId]
            delete newColumnData[id]['added']
            newColumnData[id]['edited'] = false
            delete newColumnData[colId]
            let newColumnOrder = Array.from(this.state.table.columnOrder)
            newColumnOrder.splice(index, 1, id)
            this.setState({
                table: {
                    ...this.state.table,
                    columnData: newColumnData,
                    columnOrder: newColumnOrder,
                }
            })
        })
    }

    onEditTableColumnAddCancel = columnId => {
        let newColumnData = Object.assign(this.state.table.columnData)
        delete newColumnData[columnId]
        this.setState({
            table: {
                ...this.state.table,
                columnData: newColumnData,
                columnOrder: this.state.table.columnOrder.filter(c => c !== columnId)
            }
        })
    }

    onEditTableColumnChange = e => {
        this.setState({
            table: {
                ...this.state.table,
                columnData: {
                    ...this.state.table.columnData,
                    [e.target.name]: {
                        ...this.state.table.columnData[e.target.name],
                        edited: true,
                        name: e.target.value
                    }
                }
            }
        })
    }

    onEditTableColumnChangeSave = colId => {
        this.props.firebase.updateColumnName(this.userid, this.state.table.id, colId, this.state.table.columnData[colId].name)
        this.setState({
            table: {
                ...this.state.table,
                columnData: {
                    ...this.state.table.columnData,
                    [colId]: {
                        ...this.state.table.columnData[colId],
                        edited: false
                    }
                }
            }
        })
    }

    onEditTableColumnChangeCancel = colId => {
        let prevColumnName = this.state.mydb.find(t => t.id === this.state.table.id).columnData[colId].name
        this.setState({
            table: {
                ...this.state.table,
                columnData: {
                    ...this.state.table.columnData,
                    [colId]: {
                        ...this.state.table.columnData[colId],
                        edited: false,
                        name: prevColumnName
                    }
                }
            }
        })
    }

    onEditTableColumnDelete = colId => {
        this.setState({
            table: {
                ...this.state.table,
                columnData: {
                    ...this.state.table.columnData,
                    [colId]: {
                        ...this.state.table.columnData[colId],
                        removed: true,
                    }
                }
            }
        })
    }

    onEditTableColumnDeleteSave = colId => {
        this.props.firebase.deleteColumn(this.userid, this.state.table.id, colId, this.state.table.columnData[colId].name)
        this.setState({
            table: {
                ...this.state.table,
                columnOrder: this.state.table.columnOrder.filter(c => c !== colId)
            }
        })
    }

    onEditTableColumnDeleteCancel = colId => {
        this.setState({
            table: {
                ...this.state.table,
                columnData: {
                    ...this.state.table.columnData,
                    [colId]: {
                        ...this.state.table.columnData[colId],
                        removed: false,
                    }
                }
            }
        })
    }

    onTableDelete = (tableId) => {
        if (window.confirm('Are you sure you want to delete this table?')) this.props.firebase.deleteTable(this.userid, tableId)
    }

    render() {
        let searchReg = new RegExp((this.state.search).replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'), 'i')
        return <Fragment>
            {this.state.addProject
                && <Modal show={this.state.addProject} no={this.onAddProjectClick} >
                    <div className={styles.addProjectModal}>
                        <h1>Add Project</h1>
                        <div>
                            <label>Name</label>
                            <input
                                type="textbox"
                                value={this.state.newProject.name}
                                maxLength="50"
                                name="name"
                                pattern="[a-zA-Z0-9_\s]+"
                                title="No special characters"
                                onChange={this.onNewProjectHandler}
                                autoFocus />
                            <sub className={styles.specialSubMessages}>No special characters except underscore(_). Spaces allowed.</sub>
                        </div>
                        <div>
                            <label>Description</label>
                            <input
                                type="textbox"
                                value={this.state.newProject.description}
                                maxLength="60"
                                name="description"
                                onChange={this.onNewProjectHandler} />
                            <sub className={styles.specialSubMessages}>Only 60 characters.</sub>
                        </div>
                        <button onClick={this.onAddProjectSubmit} >Submit</button>
                    </div>
                </Modal>}
            {this.state.project.pid !== null
                && <Modal show={this.state.project.pid !== null} no={this.onSelectHandler} >
                    <div className={styles.addProjectModal}>
                        <h1>Edit Project</h1>
                        <div>
                            <label>Name</label>
                            <input
                                type="textbox"
                                maxLength="50"
                                value={this.state.project.name}
                                name="name"
                                pattern="[a-zA-Z0-9_\s]+"
                                title="No special characters"
                                onChange={this.onEditHandler}
                                autoFocus />
                            <sub className={styles.specialSubMessages}>No special characters except underscore(_). Spaces allowed.</sub>
                        </div>
                        <div>
                            <label>Description</label>
                            <input
                                type="textbox"
                                maxLength="60"
                                value={this.state.project.description}
                                name="description"
                                onChange={this.onEditHandler} />
                            <sub className={styles.specialSubMessages}>Only 60 characters.</sub>
                        </div>
                        <button onClick={this.onEditSubmitHandler}>Update</button>
                    </div>
                </Modal>}
            {this.state.addTable
                && <Modal show={this.state.addTable} no={this.onTableCreateClick}>
                    <div className={styles.tableModal}>
                        <h1>Add Table</h1>
                        <div className={styles.tableNameLabel}>
                            <label>Table Name</label>
                            <input
                                type="textbox"
                                value={this.state.newTable.name}
                                maxLength="50"
                                name="name"
                                autoFocus
                                className={this.state.uniqueTableName !== null && styles.inputError}
                                pattern="[a-zA-Z0-9_\s]+"
                                title="No special characters"
                                onChange={this.onNewTablePropertyChange}
                                onBlur={this.checkNewTableUniqueName} />
                            {this.state.uniqueTableName !== null
                                && <sub className={styles.inputError}>{this.state.uniqueTableName}</sub>}
                            <sub className={styles.specialSubMessages}>No special characters except underscore(_). Spaces allowed.</sub>
                        </div>
                        {/* <div className={styles.tableNameLabel}>
                            <label>Description</label>
                            <input
                                type="textbox"
                                value={this.state.newTable.description}
                                maxLength="50"
                                name="description"
                                onChange={this.onNewTablePropertyChange} />
                        </div> */}
                        <h2>Columns</h2>
                        {this.state.newTable.column.length === 0
                            && <button onClick={() => this.onNewTableColumnAdd(0)} className={styles.noColumns}>No! I don't want to create a table with no columns</button>}
                        {this.state.newTable.column.map(col => {
                            return <div key={'column' + col.number} className={styles.tableColumnArea}>
                                <label>Column name {col.number}</label>
                                <input
                                    type="textbox"
                                    value={col.columnName}
                                    maxLength="50"
                                    name={col.number}
                                    onChange={this.onNewTableColumnChange} />
                                <button onClick={() => this.onNewTableColumnAdd(col.number)} title="Add a new column">&#43;</button>
                                <button onClick={() => this.onNewTableColumnDelete(col.number)} title="Delete this column">&minus;</button>
                            </div>
                        })}
                        <button onClick={this.onTableCreate} disabled={this.state.newTable.column.length === 0 || this.state.uniqueTableName !== null} >Submit</button>
                    </div>
                </Modal>}
            {Object.keys(this.state.table).length !== 0
                && <Modal show={Object.keys(this.state.table).length !== 0} no={this.onTableEditClick}>
                    <div className={styles.tableModal}>
                        <h1>Edit Table</h1>
                        <div className={styles.tableNameLabel}>
                            <label>Table Name</label>
                            <div className={styles.tableNameInput}>
                                <input
                                    type="textbox"
                                    value={this.state.table.name}
                                    maxLength="50"
                                    name="name"
                                    className={this.state.uniqueTableName !== null && styles.inputError}
                                    pattern="[a-zA-Z0-9_\s]+"
                                    title="No special characters"
                                    onChange={this.onEditTableNameChange}
                                    autoFocus />
                                {this.state.table.tableNameEdited && this.state.uniqueTableName === null
                                    && <Fragment>
                                        <button onClick={this.onEditTableNameChangeSave} title="Save new table name"><i className="fa fa-floppy-o" /></button>
                                        <button onClick={this.onEditTableNameChangeCancel} title="The last one was better"><i className="fa fa-times" /></button>
                                    </Fragment>}
                            </div>
                            {this.state.uniqueTableName !== null
                                && <sub className={styles.inputError}>{this.state.uniqueTableName}</sub>}
                            <sub className={styles.specialSubMessages}>No special characters except underscore(_). Spaces allowed.</sub>
                        </div>
                        {/* <div className={styles.tableNameLabel}>
                            <label>Description</label>
                            <input
                                type="textbox"
                                value={this.state.table.description}
                                maxLength="50"
                                name="description"
                                onChange={this.onEditTableDescriptionChange} />
                            {this.state.table.tableDescriptionEdited
                                && <Fragment>
                                    <button onClick={this.onEditTableDescriptionChangeSave} title="Save new table description"><i className="fa fa-floppy-o" /></button>
                                    <button onClick={this.onEditTableDescriptionChangeCancel} title="The last one was better"><i className="fa fa-times" /></button>
                                </Fragment>}
                        </div> */}
                        <h2>Columns</h2>
                        {Object.keys(this.state.table.columnData).length === 0
                            && <button onClick={() => { }} className={styles.noColumns}>No! I don't want to create a table with no columns</button>}
                        {this.state.table.columnOrder.map(cid => {
                            if (this.state.table.columnData[cid].removed) {
                                return <div key={'column' + cid} className={styles.tableColumnArea}>
                                    <label>Column '{this.state.table.columnData[cid].name}' and its data will be permanently removed if you click '<i className="fa fa-floppy-o" />'.</label>
                                    <button onClick={() => this.onEditTableColumnDeleteSave(cid)} title="Save column delete action"><i className="fa fa-floppy-o" /></button>
                                    <button onClick={() => this.onEditTableColumnDeleteCancel(cid)} title="Cancel this delete"><i className="fa fa-times" /></button>
                                </div>
                            } else {
                                return <div key={'column' + cid} className={styles.tableColumnArea}>
                                    <label>Column name {this.state.table.columnOrder.indexOf(cid) + 1}</label>
                                    <input
                                        type="textbox"
                                        value={this.state.table.columnData[cid].name}
                                        maxLength="50"
                                        name={cid}
                                        onChange={this.onEditTableColumnChange} />
                                    {'added' in this.state.table.columnData[cid] && this.state.table.columnData[cid].added
                                        ? <Fragment>
                                            <button onClick={() => this.onEditTableColumnAddSave(cid)} title="Add column"><i className="fa fa-floppy-o" /></button>
                                            <button onClick={() => this.onEditTableColumnAddCancel(cid)} title="Don't add column"><i className="fa fa-times" /></button>
                                        </Fragment>
                                        : this.state.table.columnData[cid].edited
                                            ? <Fragment>
                                                <button onClick={() => this.onEditTableColumnChangeSave(cid)} title="Rename column"><i className="fa fa-floppy-o" /></button>
                                                <button onClick={() => this.onEditTableColumnChangeCancel(cid)} title="Undo rename"><i className="fa fa-times" /></button>
                                            </Fragment>
                                            : <Fragment>
                                                <button onClick={() => this.onEditTableColumnAdd(this.state.table.columnOrder.indexOf(cid) + 1)} title="Add a new column">&#43;</button>
                                                <button onClick={() => this.onEditTableColumnDelete(cid)} title="Delete this column">&minus;</button>
                                            </Fragment>}
                                </div>
                            }
                        })}
                    </div>
                </Modal>}
            <div className={styles.container}>
                <LeftSideNav applab />
                <div className={styles.projectListArea}>
                    <div className={styles.projectListHeader}>
                        {this.state.showProject ? <h1>PROJECTS</h1> : <h1>TABLES</h1>}
                        <input type="textbox" value={this.state.search} placeholder="Search" onChange={(e) => this.onSearch(e)} />
                        <div className={styles.projectListHeaderButtons}>
                            <button onClick={() => this.toggleScreen(true)} className={this.state.showProject ? styles.selected : null}>My Projects</button>
                            <button onClick={() => this.toggleScreen(false)} className={this.state.showProject ? null : styles.selected}>My Tables</button>
                        </div>
                    </div>
                    {this.props.addLoading
                        ? <div className="loading"><i className="fa fa-spinner fa-spin"></i> Adding</div>
                        : this.props.addError !== null
                        && <div className="error">Adding error: {this.props.addError}</div>}
                    {this.props.editLoading
                        ? <div className="loading"><i className="fa fa-spinner fa-spin"></i> Editing</div>
                        : this.props.editError !== null
                        && <div className="error">Editing error: {this.props.editError}</div>}
                    {this.props.deleteLoading
                        ? <div className="loading"><i className="fa fa-spinner fa-spin"></i> Deleting</div>
                        : this.props.deleteError !== null
                        && <div className="error">Deleting error: {this.props.deleteError}</div>}
                    {this.state.showProject
                        ? <div className={styles.projectList}>
                            <div className={styles.addProject} onClick={this.onAddProjectClick}>
                                <div className={styles.addBubble}>
                                    <img src={addProjectIcon} alt="add project" />
                                </div>
                                <span>Add Project</span>
                            </div>
                            {this.props.projLoading
                                ? <div className="loading"><i className="fa fa-spinner fa-spin"></i> Loading</div>
                                : this.props.projError !== null
                                    ? <div className="error">{this.props.projError}</div>
                                    : this.state.search !== ""
                                        ? Object.values(this.props.projects).filter(pr => pr.name.match(searchReg)).map(pr => {
                                            return <SingleProject
                                                key={pr._id}
                                                name={pr.name}
                                                description={pr.description}
                                                id={pr._id}
                                                createdOn={pr.createdAt}
                                                delete={this.onDeleteHandler}
                                                edit={this.onSelectHandler} />
                                        })
                                        : Object.values(this.props.projects).map(pr => {
                                            return <SingleProject
                                                key={pr._id}
                                                name={pr.name}
                                                description={pr.description}
                                                id={pr._id}
                                                createdOn={pr.createdAt}
                                                delete={this.onDeleteHandler}
                                                edit={this.onSelectHandler} />
                                        })
                            }
                        </div>
                        : <div className={styles.projectList}>
                            <div className={styles.addProject} onClick={this.onTableCreateClick}>
                                <div className={styles.addBubble}>
                                    <img src={addTableIcon} alt="add table" />
                                </div>
                                <span>Add Table</span>
                            </div>
                            {this.state.search !== ""
                                ? this.state.mydb.filter(t => t.name.match(searchReg)).map(t => {
                                    return <SingleProject
                                        key={t.id}
                                        name={t.name}
                                        id={t.id}
                                        createdOn={t.createdOn}
                                        delete={this.onTableDelete}
                                        edit={this.onTableEditClick}
                                        table />
                                })
                                : this.state.mydb.map(t => {
                                    return <SingleProject
                                        key={t.id}
                                        name={t.name}
                                        id={t.id}
                                        createdOn={t.createdOn}
                                        delete={this.onTableDelete}
                                        edit={this.onTableEditClick}
                                        table />
                                })}
                        </div>}
                </div>
            </div>
        </Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        projects: state.projects.projects,
        projLoading: state.projects.projectLoading,
        projError: state.projects.projectError,
        addLoading: state.projects.addLoading,
        addError: state.projects.addError,
        deleteLoading: state.projects.deleteLoading,
        deleteError: state.projects.deleteError,
        editLoading: state.projects.editLoading,
        editError: state.projects.editError,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addProject: (project) => dispatch(actions.addProject(project)),
        fetchProjects: (userid) => dispatch(actions.fetchProjects(userid)),
        editProject: (project) => dispatch(actions.editProject(project)),
        deleteProject: (projectId) => dispatch(actions.deleteProject(projectId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);