import React, { Component } from 'react'
// Redux
import { connect } from 'react-redux';
import { addComponent, changePosition, changeParent } from '../../redux/actions'
//Components
import styles from './preview.module.css'
import { simpleComponent as SimpleComponent } from './simpleComponent/simpleComponent'
import { advancedComponent as AdvancedComponent } from './advancedComponent/advancedComponent'

class Preview extends Component {

  dragStartHandler = e => {
    e.stopPropagation()
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('changeOrder', true)
    e.dataTransfer.setData('id', e.target.getAttribute('data-id'))
    e.dataTransfer.setData('parent', e.target.getAttribute('data-parent'))
    let component = this.props.components.find(c => c._id === e.target.getAttribute('data-id'))
    this.props.getChildIds(component._id)
    return true
  }

  parentDragOverHandler = e => {
    e.preventDefault()
  }

  parentDragLeaveHandler = e => {
    e.preventDefault()
  }

  parentDragEndHandler = e => {
    e.preventDefault()
    this.props.emptyChildIds()
  }

  parentDropHandler = e => {
    e.stopPropagation()
    e.preventDefault()
    let drop_id = e.target.getAttribute('data-id')
    let drop_parent
    if (e.dataTransfer.getData('changeOrder') === 'true') {
      if (this.props.draggedChildIds.includes(drop_id)) {
        alert('So you want to put a parent inside its own child. \nOh human, why you want them to have an awkward family conversation?')
      } else {
        let my_parent = e.dataTransfer.getData('parent')
        let my_id = e.dataTransfer.getData('id')
        if (my_id !== drop_parent) {
          if (drop_id === this.props.screen) {
            // console.log('moving to screen')
            this.props.changeParent(my_parent, this.props.screen, my_id, null)
          } else {
            let drop_type = e.target.getAttribute('data-comptype')
            drop_parent = e.target.getAttribute('data-parent')
            if (drop_type === 'advanced') {
              // console.log('moving to advanced component ', drop_id, ' my id ', my_id)
              this.props.changeParent(my_parent, drop_id, my_id, null)
            } else if (drop_type === 'simple') {
              if (drop_parent !== my_parent) {
                let drop_pos = this.props.components.find(x => x._id === drop_parent).children.indexOf(drop_id)
                // console.log('moving to another parent ', drop_parent, ' at position ', drop_pos)
                this.props.changeParent(my_parent, drop_parent, my_id, drop_pos)
              } else {
                let drop_pos = this.props.components.find(x => x._id === my_parent).children.indexOf(drop_id)
                // console.log('changing position ', my_id, ' at position ', drop_pos)
                this.props.changePosition(my_id, drop_pos)
              }
            }
          }
        }
      }
    } else {
      let type = e.dataTransfer.getData('type')
      let drop_type = e.target.getAttribute('data-comptype')
      let drop_id = e.target.getAttribute('data-id')
      if (drop_id === this.props.screen) {
        // console.log('adding to screen')
        this.props.addComponent(this.props.projectId, type, this.props.screen, null)
      }
      else {
        if (drop_type === 'advanced') {
          // console.log('adding component on advanced ', type, drop_id)
          this.props.addComponent(this.props.projectId, type, drop_id, null)
        } else if (drop_type === 'simple') {
          drop_parent = e.target.getAttribute('data-parent')
          let drop_pos = this.props.components.find(x => x._id === drop_parent).children.indexOf(drop_id)
          // console.log('adding component on simple ', type, drop_parent, drop_pos)
          this.props.addComponent(this.props.projectId, type, drop_parent, drop_pos)
        } else if (drop_type === 'invisibleSimple') {
          // console.log('adding invisible simple component.')
          this.props.addComponent(this.props.projectId, type, this.props.screen, null)
        }
      }
    }
    this.props.onChange()
    this.props.emptyChildIds()
  }

  childRenderer = (compId) => {
    let comp = this.props.components.find(x => x._id === compId)
    if (comp.type === 'advanced') {
      return <AdvancedComponent
        key={comp._id}
        draggable
        dataId={comp._id}
        name={comp.name}
        type={comp.c_type}
        properties={comp.properties}
        styles={comp.style}
        dataParent={comp.parent}
        onSelect={this.props.onSelect}
        selected={comp._id === this.props.selectedComponent}
        dragStart={this.dragStartHandler}>
        {comp.children.length !== 0
          ? comp.children.map(child => this.childRenderer(child))
          : null
        }
      </AdvancedComponent>
    } else if (comp.type === 'simple') {
      return <SimpleComponent
        key={comp._id}
        draggable
        dataId={comp._id}
        name={comp.name}
        type={comp.c_type}
        properties={comp.properties}
        styles={comp.style}
        dataParent={comp.parent}
        onSelect={this.props.onSelect}
        selected={comp._id === this.props.selectedComponent}
        dragStart={this.dragStartHandler} />
    }
  }

  render() {

    let thisScreen = this.props.components.find(c => c._id === this.props.screen)
    let personalStyle = {}
    if (thisScreen !== null && thisScreen !== undefined) {
      if (typeof thisScreen.properties[Object.keys(thisScreen.properties)[0]] === 'object') {
        Object.keys(thisScreen.properties).map(propertyName => {
          personalStyle[propertyName] = thisScreen.properties[propertyName].value
          return null
        })
        Object.keys(thisScreen.style).map(styleName => {
          personalStyle[styleName] = thisScreen.style.styleName
          return null
        })
      } else {
        personalStyle = thisScreen.properties
      }
    }

    return (
      <div className={styles.preview_screen}>
        <div id={styles.mobile_screen}
          data-id={this.props.screen}
          onDropCapture={this.parentDropHandler}
          onDragOverCapture={this.parentDragOverHandler}
          onDragLeaveCapture={this.parentDragLeaveHandler}
          onDragEndCapture={this.parentDragEndHandler}
          style={personalStyle}>
          {this.props.compLoading
            ? <div className="loading"><i className="fa fa-spinner fa-spin"></i> Loading</div>
            : this.props.compError !== null
              ? <div className="error">Error occured: {this.props.compError}</div>
              : this.props.components.filter(x => x._id === this.props.screen).map(c => {
                if (c.type === 'advanced' && c.children.length !== 0) {
                  return c.children.map(child => {
                    let comp = this.props.components.find(x => x._id === child)
                    if (comp !== null && comp !== undefined) {
                      return this.childRenderer(comp._id)
                    } else {
                      return <span key={child}>{child}</span>
                    }
                  })
                } else {
                  return (
                    <div data-id={this.props.screen} key="noComponentScreen" >
                      <span data-id={this.props.screen} >Drag elements here!</span>
                    </div>
                  )
                }
              })
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  components: state.component.components,
  compLoading: state.component.fetchLoading,
  compError: state.component.fetchError,
})

const mapDispatchToProps = dispatch => ({
  addComponent: (projectId, type, parentId, pos) => dispatch(addComponent(projectId, type, parentId, pos)),
  changePosition: (cid, new_position) => dispatch(changePosition(cid, new_position)),
  changeParent: (oldParentId, newParentId, childId, pos) => dispatch(changeParent(oldParentId, newParentId, childId, pos)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Preview)