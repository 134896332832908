import React from 'react'
// Constants
import { k_buttonid, k_labelid, k_imageid, k_textInputid, k_switchid, k_videoid, k_itk_red, k_dateTimeid, k_iconid, k_radioButtonid } from '../../../constants'
// Styles
// Components
const Switch = React.lazy(() => import('../../ui/switch/switch').then(module => ({ default: module.Switch })))
const TimePicker = React.lazy(() => import('../../ui/timePicker/timePicker').then(module => ({ default: module.TimePicker })))
const DatePicker = React.lazy(() => import('../../ui/datePicker/datePicker').then(module => ({ default: module.DatePicker })))
// Styles

export const simpleComponent = (props) => {

    let personalStyle = {}

    personalStyle = {}
    if (typeof props.properties[Object.keys(props.properties)[0]] === 'object') {
        Object.keys(props.properties).map(propertyName => {
            if (propertyName === 'resizeMode') {
                personalStyle['objectFit'] = props.properties[propertyName].value
            }
            personalStyle[propertyName] = props.properties[propertyName].value
            return null
        })
        if ('styles' in props && props.styles !== null && props.styles !== undefined) {
            Object.keys(props.styles).map(styleName => {
                personalStyle[styleName] = props.styles[styleName]
                return null
            })
        }
    } else {
        personalStyle = props.properties
    }

    if (props.selected) {
        personalStyle['borderStyle'] = 'dashed'
        personalStyle['borderWidth'] = '1px'
        personalStyle['borderColor'] = k_itk_red
    }

    let src

    switch (props.type) {
        case k_buttonid:
            return <button
                data-id={props.dataId}
                data-parent={props.dataParent}
                data-comptype="simple"
                draggable
                onDragStart={props.dragStart}
                onClick={() => props.onSelect(props.dataId)}
                style={personalStyle}>{personalStyle.text}</button>
        case k_labelid:
            return <label
                data-id={props.dataId}
                data-parent={props.dataParent}
                data-comptype="simple"
                draggable
                onDragStart={props.dragStart}
                onClick={() => props.onSelect(props.dataId)}
                style={personalStyle}>{props.properties.text.value}</label>
        case k_textInputid:
            return <input
                data-id={props.dataId}
                data-parent={props.dataParent}
                data-comptype="simple"
                draggable
                onDragStart={props.dragStart}
                onClick={() => props.onSelect(props.dataId)}
                style={personalStyle}
                name={props.properties.name.value}
                type={props.properties.type.value}
                placeholder={props.properties.placeholder.value}
            />
        case k_imageid:
            if (Array.isArray(props.properties.src.value)) {
                src = props.properties.src.value.find(i => i.selected === 1).location
            }
            else {
                src = props.properties.src.value
            }
            return <img
                data-id={props.dataId}
                data-parent={props.dataParent}
                data-comptype="simple"
                draggable
                onDragStart={props.dragStart}
                onClick={() => props.onSelect(props.dataId)}
                style={personalStyle}
                src={src}
                alt={props.properties.alt.value}
            />
        case k_switchid:
            personalStyle['rowGap'] = personalStyle['columnGap']
            return <div style={personalStyle}
                onClick={() => props.onSelect(props.dataId)}
                data-id={props.dataId}
                data-parent={props.dataParent}
                data-comptype="simple"
                draggable
                onDragStart={props.dragStart}>
                <label>{props.properties.label.value}</label>
                <React.Suspense fallback={<div className="loading">Loading...</div>}>
                    <Switch
                        value={props.properties.value.value}
                        thumbColor={props.properties.thumbColor.value}
                        trueColor={props.properties.trueTrackColor.value}
                        falseColor={props.properties.falseTrackColor.value}
                        disabled={props.properties.disabled.value} />
                </React.Suspense>
            </div>
        case k_videoid:
            if (Array.isArray(props.properties.src.value)) {
                src = props.properties.src.value.find(i => i.selected === 1).location
            }
            else {
                src = props.properties.src.value
            }
            return <video
                data-id={props.dataId}
                data-parent={props.dataParent}
                data-comptype="simple"
                draggable
                onDragStart={props.dragStart}
                onClick={() => props.onSelect(props.dataId)}
                controls={props.properties.useNativeControls.value === 'true'}
                loop={props.properties.isLooping.value === 'true'}
                autoPlay={props.properties.autoplay.value === 'true'}
                muted={props.properties.isMuted.value === 'true'}
                style={personalStyle} >
                <source src={src} />
            </video>
        case k_dateTimeid:
            // return <input data-id={props.dataId}
            //     data-parent={props.dataParent}
            //     data-comptype="simple"
            //     draggable
            //     onDragStart={props.dragStart}
            //     onClick={() => props.onSelect(props.dataId)}
            //     style={personalStyle}
            //     type={props.properties.mode.value}
            //     value={props.properties.value.value} />
            if (props.properties.mode.value === 'date') {
                return <React.Suspense fallback={<div className="loading">Loading...</div>} >
                    <div data-id={props.dataId}
                        data-parent={props.dataParent}
                        data-comptype="simple"
                        draggable
                        onDragStart={props.dragStart}
                        onClick={() => props.onSelect(props.dataId)} style={personalStyle}>
                        <DatePicker />
                    </div>
                </React.Suspense>
            }
            else if (props.properties.mode.value === 'time') {
                return <React.Suspense fallback={<div className="loading">Loading...</div>} >
                    <div data-id={props.dataId}
                        data-parent={props.dataParent}
                        data-comptype="simple"
                        draggable
                        onDragStart={props.dragStart}
                        onClick={() => props.onSelect(props.dataId)}
                        style={personalStyle}>
                        <TimePicker />
                    </div>
                </React.Suspense>
            }
            else return null
        case k_iconid:
            personalStyle['fontSize'] = personalStyle['size'] + 'px'
            return <i data-id={props.dataId}
                data-parent={props.dataParent}
                data-comptype="simple"
                draggable
                onDragStart={props.dragStart}
                onClick={() => props.onSelect(props.dataId)}
                className={['fa', ("fa-" + props.properties.icon.value)].join(' ')} style={personalStyle} ></i>
        case k_radioButtonid:
            return <div style={personalStyle}
                onClick={() => props.onSelect(props.dataId)}
                data-id={props.dataId}
                data-parent={props.dataParent}
                data-comptype="simple"
                draggable
                onDragStart={props.dragStart}>
                <label>{props.properties.label.value}</label>
                <input type="radio" value={props.properties.label.value} name={props.properties.group.value} />
            </div>
        default:
            return <span style={{ ...personalStyle, textAlign: "center", margin: "2px" }}
                onClick={() => props.onSelect(props.dataId)}
                data-id={props.dataId}
                data-parent={props.dataParent}
                data-comptype="simple"
                draggable
                onDragStart={props.dragStart}>Component under development.</span>
    }
}