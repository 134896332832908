import React, { Component, Fragment } from 'react'
// Redux
import { connect } from 'react-redux'
import { changeProperty_OnTheGo } from '../../../redux/actions'
// Styles
import styles from './specialProperty.module.css'

class SpecialProperty extends Component {

    constructor(props) {
        super(props)
        this.state = {
            value: this.props.value,
            changed: false,
        }
    }

    async shouldComponentUpdate(nextProps, nextState) {
        if (this.props.id !== nextProps.id) {
            this.setState({
                value: nextProps.value
            })
            return true
        }
        return false
    }

    onEditClick = () => {
        this.setState({ changed: true })
    }

    onEdit = e => {
        this.props.onTheGoEdit(this.props.id, this.props.name, e.target.value)
        this.setState({ value: e.target.value, changed: true })
    }

    onSpecialEdit = (e, inputtype) => {
        switch (inputtype) {
            case 'changableUnit':
                let value = ""
                if (this.state.value.match(/(\d*\.?\d*)(.*)/)) value = this.state.value.match(/(\d*\.?\d*)(.*)/)[1]
                else if (!!this.state.value) value = this.state.value
                this.props.onTheGoEdit(this.props.id, this.props.name, value + e.target.value)
                this.setState({ value: value + e.target.value, changed: true })
                break
            case 'changableUnitValue':
                let unit = ""
                if (this.state.value.match(/(\d*\.?\d*)(.*)/)) unit = this.state.value.match(/(\d*\.?\d*)(.*)/)[2]
                else if (!!this.state.value) unit = this.state.value
                this.props.onTheGoEdit(this.props.id, this.props.name, e.target.value + unit)
                this.setState({ value: e.target.value + unit, changed: true })
                break
            default:
                break;
        }
    }

    onSave = () => {
        this.setState({ changed: false })
        this.props.edit(this.props.id, this.props.name, this.state.value)
    }

    onCancel = () => {
        this.props.onTheGoEdit(this.props.id, this.props.name, this.props.value)
        this.setState({ value: this.props.value, changed: false })
    }

    render() {
        let name = this.props.name.replace(this.props.subCategory, '')
        let inputbox = <input type="textbox" name={this.props.name} value={this.state.value} onChange={this.onEdit} />
        if (this.props.name === 'width' || this.props.name === 'height'
            || this.props.name === 'fontSize' || this.props.name === 'borderRadius'
            || this.props.name === 'borderWidth' || (this.props.name).match(/padding.*/)
            || (this.props.name).match(/margin.*/) || this.props.name === 'columnGap') {
            if ((this.state.value).match(/px/)) {
                inputbox = <div className={styles.changableUnit}>
                    <input type="number" min="0" name={this.props.name} value={(this.state.value).replace(/px/, '')} onChange={(e) => this.onSpecialEdit(e, 'changableUnitValue')} />
                    <select value="px" onChange={(e) => this.onSpecialEdit(e, 'changableUnit')}>
                        <option value=""></option>
                        <option value="px">px</option>
                        <option value="%">%</option>
                    </select>
                </div>
            }
            else if ((this.state.value).match(/%/)) {
                inputbox = <div className={styles.changableUnit}>
                    <input type="number" min="0" name={this.props.name} value={(this.state.value).replace(/%/, '')} onChange={(e) => this.onSpecialEdit(e, 'changableUnitValue')} />
                    <select value="%" onChange={(e) => this.onSpecialEdit(e, 'changableUnit')}>
                        <option value=""></option>
                        <option value="px">px</option>
                        <option value="%">%</option>
                    </select>
                </div>
            } else {
                inputbox = <div className={styles.changableUnit}>
                    <input type="number" min="0" name={this.props.name} value={(this.state.value)} onChange={(e) => this.onSpecialEdit(e, 'changableUnitValue')} />
                    <select value="" onChange={(e) => this.onSpecialEdit(e, 'changableUnit')}>
                        <option value=""></option>
                        <option value="px">px</option>
                        <option value="%">%</option>
                    </select>
                </div>
            }
        }

        name = name.replace(/([A-Z])/g, ' $1')

        return (
            <div className={styles.property}>
                <div className={styles.rowLabel}>
                    <label>{name}</label>
                    {this.state.changed
                        && <Fragment>
                            <button onClick={this.onSave}><i className="fa fa-check-circle-o" /></button>
                            <button onClick={this.onCancel}><i className="fa fa-times" /></button>
                        </Fragment>}
                </div>
                {inputbox}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    onTheGoEdit: (id, propertyName, value) => dispatch(changeProperty_OnTheGo(id, propertyName, value))
})

export default connect(null, mapDispatchToProps)(SpecialProperty)