import React from 'react'
// Components
// import Property from '../property/property'
import SpecialProperty from '../specialProperty/specialProperty'
// styles
import styles from './propertySubCategory.module.css'

export const PropertySubCategory = props => {
    return <details className={styles.details} open={true}>
        <summary className={styles.summary}>{props.subCategory}</summary>
        <div>
            {props.properties.map(propertyName => {
                return <SpecialProperty key={propertyName.name}
                    id={props.id}
                    name={propertyName.name}
                    value={propertyName.value}
                    cTypeId={props.cTypeId}
                    edit={props.edit}
                    subCategory={props.subCategory} />
            })}
        </div>
    </details>
}