// Backend URI
export const beURL = process.env.REACT_APP_BACK_END

// Project routes
const projectRoute = '/project/'
export const addProject = projectRoute + 'add' // (json) name, description, userid, active
export const getOneProject = projectRoute + 'one/' // + projectid
export const getProjects = projectRoute // + userid
export const editProject = projectRoute + 'edit' // (json) _id (optional) name, description, active, url, current_screen
export const deleteProject = projectRoute + 'delete/' // + projectid

// Component routes
const componentRoute = '/component/'
export const addComponent = componentRoute + 'add' // (json) project_id, c_type, parent_id, position
export const getComponents = componentRoute // + pid
export const rename = componentRoute + 'change_name' // (json) cid, new_name
export const changeProperty = componentRoute + 'change_property' // (json) cid, property_name, property_value
export const dropChild = componentRoute + 'drop_child' // (json) cid, childId
export const changeParent = componentRoute + 'change_parent' // (json) cid, new_parent, old_parent, new_position
export const changePosition = componentRoute + 'change_position' // (json) cid, new_position
export const updateXml = componentRoute + 'updateXml' // (json) cid, xml
export const updateCode = componentRoute + 'updateCode' // (json) cid, code
export const uploadImage = componentRoute + 'uploadImage' // (form-data) cid, image
export const changeSelectedImage = componentRoute + 'changeSelectedImage' // (json) cid, location
export const deleteComponent = componentRoute + 'delete/' // + cid
export const deleteChildren = componentRoute + 'deleteChildren' // (json) cid, childids[]
export const duplicateComponent = componentRoute + 'duplicate/' // + cid

// MetaComponent routes
export const getMetaComponents = '/metaComponent/'


/////////////////////// TEMPLATE ENGINE ///////////////////////////
// Template engine URI
export const teURL = process.env.REACT_APP_TEMPLATE_ENGINE

// Build routes
const tempEngine = '/build/'
export const buildProject = tempEngine // + projectid

/////////////////////// DATA API ///////////////////////////
export const daURL = process.env.REACT_APP_DATA_API
//  Post data
export const postData = '/data'