import React, { Component, Fragment } from 'react';
import styles from './blocklyComponent.module.css';
import Blockly from 'blockly/core';
import BlocklyJS from 'blockly/javascript';
import locale from 'blockly/msg/en';
import 'blockly/blocks';
// Redux
import { connect } from 'react-redux';
// Custom blocks imports
import { Block, Category } from './index'
import '../../blocks/customBlocks'
import '../../blocks/generator'
// Constants
import { k_buttonid, k_labelid, k_screenid, k_textInputid, k_imageid } from '../../constants'

Blockly.setLocale(locale);

class BlocklyComponent extends Component {

    constructor(props) {
        super(props)
        this.getComponents = (compId, compArr) => {
            let component = this.props.components.find(x => x._id === compId)
            if (compArr.find(x => x._id === compId)) { return null }
            else {
                compArr.push(component)
                if (component.type === 'advanced' && component.children.length !== 0) {
                    component.children.map(child => this.getComponents(child, compArr))
                }
                return compArr
            }
        }
        this.generateXML = (componentList) => {
            let xml = [
                <Category name="General" key="generalCategory">
                    <Block type="set_color"></Block>
                    <Block type="set_text_input"></Block>
                    <Block type="al_numerical_input"></Block>
                    <Block type="al_boolean"></Block>
                    <Block type="al_keyboard_type_input"></Block>
                    <Block type="al_screen_justification"></Block>
                    <Block type="al_screen_alignment"></Block>
                    <Block type="al_move_screen"></Block>
                </Category>,
                // <sep key="generalSeparator"></sep>
            ]
            componentList.map(comp => {
                switch (comp.c_type) {
                    case k_screenid:
                        xml.push(<Category key={comp._id} name={comp.name}>
                            <Block type="when_do_screen"></Block>
                            <Block type="al_screen_getter"></Block>
                            <Block type="al_screen_setter"></Block>
                            <Block type="al_move_screen"></Block>
                            <Block type="set_color"></Block>
                            <Block type="set_text_input"></Block>
                            <Block type="al_screen_justification"></Block>
                            <Block type="al_screen_alignment"></Block>
                        </Category>)
                        return null
                    case k_buttonid:
                        xml.push(<Category key={comp._id} name={comp.name}>
                            <Block type="when_do_buttons"></Block>
                            <Block type="al_button_getter"></Block>
                            <Block type="al_button_setter"></Block>
                            <Block type="set_color"></Block>
                            <Block type="set_text_input"></Block>
                        </Category>)
                        return null
                    case k_labelid:
                        xml.push(<Category key={comp._id} name={comp.name}>
                            <Block type="al_label_getter"></Block>
                            <Block type="al_label_setter"></Block>
                            <Block type="set_color"></Block>
                            <Block type="set_text_input"></Block>
                        </Category>)
                        return null
                    case k_textInputid:
                        xml.push(<Category key={comp._id} name={comp.name}>
                            <Block type="al_text_input_getter"></Block>
                            <Block type="al_text_input_setter"></Block>
                            <Block type="al_keyboard_type_input"></Block>
                        </Category>)
                        return null
                    case k_imageid:
                        xml.push(<Category key={comp._id} name={comp.name}>
                            <Block type="al_image_getter"></Block>
                            <Block type="al_image_setter"></Block>
                            <Block type="al_screen_justification"></Block>
                            <Block type="al_screen_alignment"></Block>
                        </Category>)
                        return null
                    // case k_dataid:
                    //     xml.push(<Category key={comp._id} name={comp.name}>
                    //         <Block type="al_insert_table_row"></Block>
                    //         <Block type="al_get_table_row"></Block>
                    //         <Block type="al_where_row_column"></Block>
                    //         <Block type="al_next_row"></Block>
                    //         <Block type=""></Block>
                    //     </Category>)
                    //     return null
                    default:
                        return null
                }
            })
            // if (JSON.parse(localStorage.getItem('applab_myTables')).length !== 0) {
            //     xml.push(<Category key="Table" name="Tables">
            //         <Block type="al_insert_table_row"></Block>
            //         <Block type="al_get_table_row"></Block>
            //         <Block type="al_where_row_column"></Block>
            //         <Block type="al_next_row"></Block>
            //         <Block type="al_column_name"></Block>
            //     </Category>)
            // }
            return xml
        }
        let compList = this.getComponents(this.props.current_screen, [])
        let initialToolbar = this.generateXML(compList)
        this.state = {
            initialToolbar: initialToolbar,
        }
    }

    componentDidMount() {
        const { initialXml, children, ...rest } = this.props;
        this.primaryWorkspace = Blockly.inject(
            this.blocklyDiv,
            {
                toolbox: this.toolbox,
                zoom: {
                    controls: true,
                    wheel: true,
                    startScale: 1.0,
                    maxScale: 3,
                    minScale: 0.3,
                    scaleSpeed: 1.2
                },
                ...rest
            },
        );

        if (initialXml) {
            Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(initialXml), this.primaryWorkspace);
        }
        this.primaryWorkspace.addChangeListener(this.onEveryChange)
    }

    // componentWillUnmount() {
    //     this.onEveryChange()
    // }

    onEveryChange = () => {
        this.saveCode()
        this.saveXml()
    }

    saveCode = () => {
        var code = BlocklyJS.workspaceToCode(this.primaryWorkspace);
        let updateScreenCode = {
            cid: this.props.current_screen,
            code: code,
        }
        this.props.onUpdateCode(updateScreenCode)
    }

    saveXml = () => {
        var xml = Blockly.Xml.workspaceToDom(this.primaryWorkspace);
        var xml_text = Blockly.Xml.domToText(xml);
        let updateScreenXml = {
            cid: this.props.current_screen,
            xml: xml_text,
        }
        this.props.onUpdateXml(updateScreenXml)
    }

    get workspace() {
        return this.primaryWorkspace;
    }

    setXml(xml) {
        Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(xml), this.primaryWorkspace);
    }

    render() {
        return <Fragment>
            <div ref={e => this.blocklyDiv = e} id={styles.blocklyDiv} />
            <xml xmlns="https://developers.google.com/blockly/xml" is="blockly" style={{ display: 'none' }} ref={(toolbox) => { this.toolbox = toolbox; }}>
                {this.state.initialToolbar}
            </xml>
        </Fragment>
    }
}

const mapStateToProps = state => ({
    components: state.component.components
})

export default connect(mapStateToProps, null)(BlocklyComponent);
