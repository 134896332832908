import React, { Component } from 'react'
// Redux
import { connect } from 'react-redux'
import { renameComponent, addComponent, changeParent, changePosition } from '../../redux/actions/index'
// Other components
import styles from './componentList.module.css'
import { SingleComponent } from './singleComponent/singleComponent'
// Constants
import { k_itk_red } from '../../constants'

class ComponentList extends Component {

    constructor(props) {
        super(props)
        this.componentTree = []
        this.invisibleComponents = []
    }

    dragStartHandler = e => {
        e.stopPropagation()
        e.dataTransfer.effectAllowed = 'move'
        e.dataTransfer.setData('changeOrder', true)
        e.dataTransfer.setData('id', e.target.getAttribute('data-id'))
        e.dataTransfer.setData('parent', e.target.getAttribute('data-parent'))
        let component = this.props.components.find(c => c._id === e.target.getAttribute('data-id'))
        this.props.getChildIds(component._id)
        return true
    }

    dragOverHandler = e => {
        e.preventDefault()
        e.target.style.borderBottom = '2px solid ' + k_itk_red
        e.target.style.borderLeft = '2px solid ' + k_itk_red
        return false
    }

    dragLeaveHandler = e => {
        e.preventDefault()
        e.target.style.borderBottom = null
        e.target.style.borderLeft = null
    }

    dragEndHandler = e => {
        e.preventDefault()
        this.props.emptyChildIds()
    }

    dropHandler = e => {
        e.stopPropagation()
        e.preventDefault()
        e.target.style.borderBottom = null
        e.target.style.borderLeft = null
        let drop_id = e.target.getAttribute('data-id')
        let drop_type = e.target.getAttribute('data-comptype')
        let drop_parent = e.target.getAttribute('data-parent')
        let drop_pos
        if (e.dataTransfer.getData('changeOrder') === 'true') {
            let my_id = e.dataTransfer.getData('id')
            let my_parent = e.dataTransfer.getData('parent')
            if (this.props.draggedChildIds.includes(drop_id)) {
                alert('So you want to put a parent inside its own child. \nOh human, why you want them to have an awkward family conversation?')
            }
            else {
                // console.log('my id ', my_id, ' my parent ', my_parent, ' drop id ', drop_id, 'drop parent ', drop_parent, ' drop comp type ', drop_compType)
                if (my_id !== drop_parent) {
                    if (drop_id === this.props.screenId) {
                        // console.log('moving to screen')
                        this.props.changeParent(my_parent, this.props.screenId, my_id, null)
                    } else {
                        if (drop_type === 'advanced') {
                            // console.log('moving to advanced component ', drop_id, ' my id ', my_id)
                            this.props.changeParent(my_parent, drop_id, my_id, null)
                        } else if (drop_type === 'simple') {
                            if (drop_parent !== my_parent) {
                                drop_pos = this.props.components.find(x => x._id === drop_parent).children.indexOf(drop_id)
                                // console.log('moving to another parent ', drop_parent, ' at position ', drop_pos)
                                this.props.changeParent(my_parent, drop_parent, my_id, drop_pos)
                            } else {
                                drop_pos = this.props.components.find(x => x._id === my_parent).children.indexOf(drop_id)
                                // console.log('changing position ', my_id, ' at position ', drop_pos)
                                this.props.changePosition(my_id, drop_pos)
                            }
                        }
                    }
                }
            }
        }
        else {
            let type = e.dataTransfer.getData('type')
            if (drop_id === this.props.screenId) {
                // console.log('adding to screen')
                this.props.addComponent(this.props.projectId, type, this.props.screenId, null)
            }
            else {
                if (drop_type === 'advanced') {
                    // console.log('adding component on advanced ', type, drop_id)
                    this.props.addComponent(this.props.projectId, type, drop_id, null)
                } else if (drop_type === 'simple') {
                    drop_pos = this.props.components.find(x => x._id === drop_parent).children.indexOf(drop_id)
                    // console.log('adding component on simple ', type, drop_parent, drop_pos)
                    this.props.addComponent(this.props.projectId, type, drop_parent, drop_pos)
                } else if (drop_type === 'invisibleSimple') {
                    // console.log('adding invisible simple component')
                    this.props.addComponent(this.props.projectId, type, this.props.screenId, null)
                }
            }
        }
        this.props.emptyChildIds()
        return false
    }

    getVisibleComponent = (componentId) => {
        let comp = {}
        comp = this.props.components.find(comp => comp._id === componentId)
        if (comp !== undefined) {
            if (comp.type === 'advanced') {
                return <SingleComponent
                    key={comp._id}
                    id={comp._id}
                    name={comp.name}
                    parent={comp.parent}
                    dragStart={this.dragStartHandler}
                    dragOver={this.dragOverHandler}
                    dragLeave={this.dragLeaveHandler}
                    dragEnd={this.dragEndHandler}
                    drop={this.dropHandler}
                    caret
                    selected={this.props.selectedComponent === comp._id}
                    onSelect={this.props.onSelect}>
                    <ul className={styles.nested}>
                        {comp.children.length !== 0
                            && comp.children.map(c => {
                                return this.getVisibleComponent(c)
                            })}
                    </ul>
                </SingleComponent>
            } else if (comp.type === 'simple') {
                return <SingleComponent
                    key={comp._id}
                    id={comp._id}
                    name={comp.name}
                    parent={comp.parent}
                    dragStart={this.dragStartHandler}
                    dragOver={this.dragOverHandler}
                    dragLeave={this.dragLeaveHandler}
                    dragEnd={this.dragEndHandler}
                    drop={this.dropHandler}
                    selected={this.props.selectedComponent === comp._id}
                    onSelect={this.props.onSelect} />
            }
        }
        return null
    };

    getInvisibleComponents = (screenId) => {
        let screenComp = this.props.components.find(comp => comp._id === screenId)
        if (screenComp !== undefined && screenComp.children.length !== 0) {
            return screenComp.children.map(cid => {
                let childComp = this.props.components.find(comp => comp._id === cid)
                if (childComp.type === 'invisibleSimple') {
                    return <SingleComponent
                        key={cid}
                        id={cid}
                        name={childComp.name}
                        parent={childComp.parent}
                        drop={this.dropHandler}
                        selected={this.props.selectedComponent === cid}
                        onSelect={this.props.onSelect} />
                }
                return null
            })
        }
    }

    render() {
        this.componentTree = this.getVisibleComponent(this.props.screenId)
        this.invisibleComponents = this.getInvisibleComponents(this.props.screenId)
        return <div className={styles.container}>
            <div className={styles.visibleComponentsContainer}>
                <h4>Visible</h4>
                <hr />
                <div className={styles.visibleComponents}>
                    <ul id={styles.myUL}>{this.componentTree}</ul>
                </div>
            </div>
            <div className={styles.invisibleComponentsContainer}>
                <h4>Invisible</h4>
                <hr />
                <div className={styles.invisibleComponents}>
                    <ul>{this.invisibleComponents}</ul>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = state => ({
    project: state.projects.projects,
    components: state.component.components,
})

const mapDispatchToProps = dispatch => ({
    renameComponent: (component) => dispatch(renameComponent(component)),
    addComponent: (projectId, type, parentId, pos) => dispatch(addComponent(projectId, type, parentId, pos)),
    changePosition: (cid, new_position) => dispatch(changePosition(cid, new_position)),
    changeParent: (oldParentId, newParentId, childId, pos) => dispatch(changeParent(oldParentId, newParentId, childId, pos)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ComponentList);
