import * as Blockly from 'blockly/core';
import { k_textInputid, k_imageid, k_labelid, k_buttonid, k_screenid, k_dataid } from '../constants'

var whenDoScreen = {
    "type": "when_do_screen",
    "message0": "when this screen opens do %1",
    "args0": [
        {
            "type": "input_statement",
            "name": "do"
        }
    ],
    "style": {
        "hat": "caps"
    },
    "nextStatement": null,
    "colour": 180,
    "tooltip": "Choose what should happen when the screen loads",
    "helpUrl": ""
}

var getterScreenBlock = {
    "type": "al_screen_getter",
    "message0": "%1 %2 get %3",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "from",
            "text": "from"
        },
        {
            "type": "input_dummy",
            "name": "current_screen_input",
        },
        {
            "type": "field_dropdown",
            "name": "screen_properties",
            "options": [
                [
                    "Background color",
                    "backgroundColor"
                ],
                [
                    "Align items vertically",
                    "justifyContent"
                ],
                [
                    "Align items horizontally",
                    "alignItems"
                ],
            ]
        }
    ],
    "extensions": ["get_current_screen"],
    "inputsInline": false,
    "output": null,
    "colour": 60,
    "tooltip": "Get Screen properties",
    "helpUrl": ""
}

var setterScreenBlock = {
    "type": "al_screen_setter",
    "message0": "%1 %2 set %3 to %4",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "for",
            "text": "for"
        },
        {
            "type": "input_dummy",
            "name": "current_screen_input",
        },
        {
            "type": "field_dropdown",
            "name": "screen_properties",
            "options": [
                [
                    "Background color",
                    "backgroundColor"
                ],
                [
                    "Align items vertically",
                    "justifyContent"
                ],
                [
                    "Align items horizontally",
                    "alignItems"
                ],
            ]
        },
        {
            "type": "input_value",
            "name": "set screen",
            "align": "RIGHT"
        }
    ],
    "extensions": ["get_current_screen"],
    "inputsInline": false,
    "previousStatement": null,
    "nextStatement": null,
    "colour": 60,
    "tooltip": "Set Screen properties",
    "helpUrl": ""
}

var navigateToAnotherScreen = {
    "type": "al_move_screen",
    "message0": "move to %1",
    "args0": [
        {
            "type": "input_dummy",
            "name": "screen_input",
        }
    ],
    "previousStatement": null,
    "nextStatement": null,
    "extensions": ["get_screens"],
    "colour": 225,
    "tooltip": "Use to move to a particular screen on an event",
    "helpUrl": ""
}

var whenDoButtons = {
    "type": "when_do_button",
    "message0": "%1 %2 %3 %4 %5",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "when",
            "text": "when"
        },
        {
            "type": "input_dummy",
            "name": "button_input",
        },
        {
            "type": "field_dropdown",
            "name": "action_options",
            "options": [
                [
                    "click",
                    "press"
                ],
                [
                    "long click",
                    "longPress"
                ]
            ]
        },
        {
            "type": "field_label_serializable",
            "name": "do",
            "text": "do"
        },
        {
            "type": "input_statement",
            "name": "do"
        }
    ],
    "extensions": ["get_buttons"],
    "style": {
        "hat": "cap"
    },
    "nextStatement": null,
    "colour": 150,
    "tooltip": "Choose the button and its actions",
    "helpUrl": ""
}

var getterBlockButton = {
    "type": "al_button_getter",
    "message0": "%1 %2 get %3",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "from",
            "text": "from"
        },
        {
            "type": "input_dummy",
            "name": "button_input",
        },
        {
            "type": "field_dropdown",
            "name": "button_properties",
            "options": [
                [
                    "Background Color",
                    "backgroundColor"
                ],
                [
                    "Text",
                    "text"
                ],
                [
                    "Text Color",
                    "color"
                ],
                [
                    "Font Size",
                    "fontSize"
                ],
                [
                    "Boldness",
                    "fontWeight"
                ],
                [
                    "Font Style",
                    "fontStyle"
                ],
                [
                    "Height",
                    "height"
                ],
                [
                    "Width",
                    "width"
                ],
                [
                    "Visible",
                    "display"
                ]
            ]
        }
    ],
    "extensions": ["get_buttons"],
    "inputsInline": false,
    "output": null,
    "colour": 30,
    "tooltip": "Get button properties",
    "helpUrl": ""
}

var setterBlockButton = {
    "type": "al_button_setter",
    "message0": "%1 %2 set %3 to %4",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "for",
            "text": "for"
        },
        {
            "type": "input_dummy",
            "name": "button_input",
        },
        {
            "type": "field_dropdown",
            "name": "button_properties",
            "options": [
                [
                    "Background Color",
                    "backgroundColor"
                ],
                [
                    "Text",
                    "text"
                ],
                [
                    "Text Color",
                    "textColor"
                ],
                [
                    "Font Size",
                    "fontSize"
                ],
                [
                    "Boldness",
                    "fontWeight"
                ],
                [
                    "Font Style",
                    "fontStyle"
                ],
                [
                    "Height",
                    "height"
                ],
                [
                    "Width",
                    "width"
                ],
                [
                    "Visible",
                    "display"
                ]
            ]
        },
        {
            "type": "input_value",
            "name": "set button",
            "align": "RIGHT"
        }
    ],
    "inputsInline": false,
    "extensions": ["get_buttons"],
    "previousStatement": null,
    "nextStatement": null,
    "colour": 30,
    "tooltip": "Set button properties",
    "helpUrl": ""
}

var labelGetterBlock = {
    "type": "al_label_getter",
    "message0": "%1 %2 get %3",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "from",
            "text": "from"
        },
        {
            "type": "input_dummy",
            "name": "label_input",
        },
        {
            "type": "field_dropdown",
            "name": "label_properties",
            "options": [
                [
                    "Background Color",
                    "backgroundColor"
                ],
                [
                    "Text",
                    "text"
                ],
                [
                    "Text Color",
                    "color"
                ],
                [
                    "Font Size",
                    "fontSize"
                ],
                [
                    "Boldness",
                    "fontWeight"
                ],
                [
                    "Font Style",
                    "fontStyle"
                ],
                [
                    "Height",
                    "height"
                ],
                [
                    "Width",
                    "width"
                ],
                [
                    "Visible",
                    "display"
                ],
            ]
        }
    ],
    "inputsInline": false,
    "output": null,
    "extensions": ["get_label"],
    "colour": 30,
    "tooltip": "Get button properties",
    "helpUrl": ""
}

var labelSetterBlock = {
    "type": "al_label_setter",
    "message0": "%1 %2 set %3 to %4",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "for",
            "text": "for"
        },
        {
            "type": "input_dummy",
            "name": "label_input",
        },
        {
            "type": "field_dropdown",
            "name": "label_properties",
            "options": [
                [
                    "Background Color",
                    "backgroundColor"
                ],
                [
                    "Text",
                    "text"
                ],
                [
                    "Text Color",
                    "color"
                ],
                [
                    "Font Size",
                    "fontSize"
                ],
                [
                    "Boldness",
                    "fontWeight"
                ],
                [
                    "Font Style",
                    "fontStyle"
                ],
                [
                    "Height",
                    "height"
                ],
                [
                    "Width",
                    "width"
                ],
                [
                    "Visible",
                    "display"
                ],
            ]
        },
        {
            "type": "input_value",
            "name": "set label",
            "align": "RIGHT"
        }
    ],
    "inputsInline": false,
    "previousStatement": null,
    "nextStatement": null,
    "extensions": ["get_label"],
    "colour": 30,
    "tooltip": "Set label properties",
    "helpUrl": ""
}

var textInputGetterBlock = {
    "type": "al_text_input_getter",
    "message0": "%1 %2 get %3",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "from",
            "text": "from"
        },
        {
            "type": "input_dummy",
            "name": "textInput_input",
        },
        {
            "type": "field_dropdown",
            "name": "text_input_properties",
            "options": [
                [
                    "Hint text",
                    "placeholder"
                ],
                [
                    "Height",
                    "height"
                ],
                [
                    "Width",
                    "width"
                ],
                [
                    "Visible",
                    "display"
                ],
                [
                    "keyboard type",
                    "type"
                ]
            ]
        }
    ],
    "inputsInline": false,
    "output": null,
    "extensions": ["get_textInput"],
    "colour": 0,
    "tooltip": "Get text input properties",
    "helpUrl": ""
}

var textInputSetterBlock = {
    "type": "al_text_input_setter",
    "message0": "%1 %2 set %3 to %4",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "for",
            "text": "for"
        },
        {
            "type": "input_dummy",
            "name": "textInput_input",
        },
        {
            "type": "field_dropdown",
            "name": "text_input_properties",
            "options": [
                [
                    "Hint text",
                    "placeholder"
                ],
                [
                    "Height",
                    "height"
                ],
                [
                    "Width",
                    "width"
                ],
                [
                    "Visible",
                    "display"
                ],
                [
                    "keyboard type",
                    "type"
                ]
            ]
        },
        {
            "type": "input_value",
            "name": "set text input",
            "align": "RIGHT"
        }
    ],
    "inputsInline": false,
    "previousStatement": null,
    "nextStatement": null,
    "extensions": ["get_textInput"],
    "colour": 0,
    "tooltip": "Set text input properties",
    "helpUrl": ""
}

var imageGetterBlock = {
    "type": "al_image_getter",
    "message0": "%1 %2 get %3",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "from",
            "text": "from"
        },
        {
            "type": "input_dummy",
            "name": "image_input"
        },
        {
            "type": "field_dropdown",
            "name": "image_properties",
            "options": [
                [
                    "Height",
                    "height"
                ],
                [
                    "Width",
                    "width"
                ],
                [
                    "Resize mode",
                    "resizeMode"
                ],
                [
                    "Visible",
                    "display"
                ],
            ]
        }
    ],
    "inputsInline": false,
    "output": null,
    "extensions": ["get_images"],
    "colour": 270,
    "tooltip": "Get image input properties",
    "helpUrl": ""
}

var imageSetterBlock = {
    "type": "al_image_setter",
    "message0": "%1 %2 set %3 to %4",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "for",
            "text": "for"
        },
        {
            "type": "input_dummy",
            "name": "image_input"
        },
        {
            "type": "field_dropdown",
            "name": "image_properties",
            "options": [
                [
                    "Height",
                    "height"
                ],
                [
                    "Width",
                    "width"
                ],
                [
                    "Resize mode",
                    "resizeMode"
                ],
                [
                    "Visible",
                    "display"
                ],
            ]
        },
        {
            "type": "input_value",
            "name": "set image",
            "align": "RIGHT"
        }
    ],
    "inputsInline": false,
    "previousStatement": null,
    "nextStatement": null,
    "extensions": ["get_images"],
    "colour": 270,
    "tooltip": "Set image input properties",
    "helpUrl": ""
}

var setColor = {
    "type": "set_color",
    "message0": "%1",
    "args0": [
        {
            "type": "field_colour",
            "name": "COLOR",
            "colour": "#ff0000"
        }
    ],
    "output": "Colour",
    "colour": 210,
    "tooltip": "",
    "helpUrl": ""
}

var setTextInput = {
    "type": "set_text_input",
    "message0": "\" %1 \"",
    "args0": [
        {
            "type": "field_input",
            "name": "NAME",
            "text": "type here"
        }
    ],
    "output": "String",
    "colour": 210,
    "tooltip": "",
    "helpUrl": ""
}

var setNumberInput = {
    "type": "al_numerical_input",
    "message0": "%1",
    "args0": [
        {
            "type": "field_number",
            "name": "number_input",
            "value": 0
        }
    ],
    "output": "Number",
    "colour": 210,
    "tooltip": "",
    "helpUrl": ""
}

var setBooleanInput = {
    "type": "al_boolean",
    "message0": "%1",
    "args0": [
        {
            "type": "field_dropdown",
            "name": "boolean",
            "options": [
                [
                    "true",
                    "true"
                ],
                [
                    "false",
                    "false"
                ]
            ]
        }
    ],
    "output": "Boolean",
    "colour": 210,
    "tooltip": "",
    "helpUrl": ""
}

var setKeyboardType = {
    "type": "al_keyboard_type_input",
    "message0": "%1 %2",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "keyboard_type",
            "text": "keyboard type"
        },
        {
            "type": "field_dropdown",
            "name": "keyboard_type_options",
            "options": [
                [
                    "default",
                    "default"
                ],
                [
                    "email address",
                    "email"
                ],
                [
                    "numerical",
                    "number"
                ],
                [
                    "masked ***",
                    "password"
                ]
            ]
        }
    ],
    "output": "String",
    "colour": 0,
    "tooltip": "",
    "helpUrl": ""
}

var setScreenJustification = {
    "type": "al_screen_justification",
    "message0": "%1 %2",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "justification",
            "text": "justification"
        },
        {
            "type": "field_dropdown",
            "name": "justification_options",
            "options": [
                [
                    "top",
                    "top"
                ],
                [
                    "bottom",
                    "bottom"
                ],
                [
                    "center",
                    "center"
                ]
            ]
        }
    ],
    "output": "String",
    "colour": 60,
    "tooltip": "Used to set justification",
    "helpUrl": ""
}

var setScreenAlignment = {
    "type": "al_screen_alignment",
    "message0": "%1 %2",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "alignment",
            "text": "alignment"
        },
        {
            "type": "field_dropdown",
            "name": "alignment_options",
            "options": [
                [
                    "left",
                    "left"
                ],
                [
                    "right",
                    "right"
                ],
                [
                    "center",
                    "center"
                ]
            ]
        }
    ],
    "output": "String",
    "colour": 60,
    "tooltip": "Used to set alignment",
    "helpUrl": ""
}

var insertTableRow = {
    "type": "al_insert_table_row",
    "message0": "in table %1 set value for column %2 for row number %3 get value from %4",
    "args0": [
        {
            "type": "input_dummy",
            "name": "table_input",
        },
        {
            "type": "input_value",
            "name": "set_value"
        },
        {
            "type": "input_value",
            "name": "row_number"
        },
        {
            "type": "input_value",
            "name": "get_value_from"
        }
    ],
    "inputsInline": false,
    "previousStatement": null,
    "nextStatement": null,
    "colour": 90,
    "tooltip": "Inserts value in the given column in a table",
    "helpUrl": "",
    "extensions": ['get_tables']
}

var getTableRow = {
    "type": "al_get_table_row",
    "message0": "in table %1 get value from column %2 for row number %3",
    "args0": [
        {
            "type": "input_dummy",
            "name": "table_input",
        },
        {
            "type": "input_value",
            "name": "get_value"
        },
        {
            "type": "input_value",
            "name": "row_number"
        }
    ],
    "inputsInline": false,
    "output": null,
    "colour": 90,
    "tooltip": "Get value from the given column in a table",
    "helpUrl": "",
    "extensions": ['get_tables']
}

var whereRowColumn = {
    "type": "al_where_row_column",
    "message0": "where column name %1 and column value %2",
    "args0": [
        {
            "type": "input_value",
            "name": "column_name"
        },
        {
            "type": "input_value",
            "name": "column_value"
        }
    ],
    "inputsInline": false,
    "output": null,
    "colour": 45,
    "tooltip": "where clause for a column and row value",
    "helpUrl": ""
}

var nextRow = {
    "type": "al_next_row",
    "message0": "next row",
    "inputsInline": false,
    "output": null,
    "colour": 45,
    "tooltip": "where clause for a column and row value",
    "helpUrl": ""
}

var columnName = {
    "type": "al_column_name",
    "message0": "%1",
    "args0": [
        {
            "type": "field_input",
            "name": "column_name",
            "text": "column name"
        }
    ],
    "inputsInline": false,
    "output": null,
    "colour": 45,
    "tooltip": "Column name",
    "helpUrl": ""
}

// Extensions

Blockly.Extensions.register('get_current_screen', function () {
    this.getInput('current_screen_input')
        .appendField(new Blockly.FieldDropdown(
            function () {
                let options = []
                let components = JSON.parse(localStorage.getItem('applab_componentList'))
                components
                    .filter(comp => comp._id === localStorage.getItem('applab_currentScreen'))
                    .map(comp => options.push([comp.name, comp._id]))
                return options
            }
        ), 'current_screen_input')
})

Blockly.Extensions.register('get_screens', function () {
    this.getInput('screen_input')
        .appendField(new Blockly.FieldDropdown(
            function () {
                let options = []
                let components = JSON.parse(localStorage.getItem('applab_componentList'))
                components
                    .filter(comp => comp.c_type === k_screenid)
                    .map(comp => options.push([comp.name, comp._id]))
                return options
            }
        ), 'screen_input')
})

Blockly.Extensions.register('get_buttons', function () {
    this.getInput('button_input')
        .appendField(new Blockly.FieldDropdown(
            function () {
                let options = []
                let components = JSON.parse(localStorage.getItem('applab_componentList'))
                components
                    .filter(comp => comp.c_type === k_buttonid)
                    .map(comp => options.push([comp.name, comp._id]))
                return options
            }
        ), 'button_input')
})

Blockly.Extensions.register('get_label', function () {
    this.getInput('label_input')
        .appendField(new Blockly.FieldDropdown(
            function () {
                let options = []
                let components = JSON.parse(localStorage.getItem('applab_componentList'))
                components
                    .filter(comp => comp.c_type === k_labelid)
                    .map(comp => options.push([comp.name, comp._id]))
                return options
            }
        ), 'label_input')
})

Blockly.Extensions.register('get_textInput', function () {
    this.getInput('textInput_input')
        .appendField(new Blockly.FieldDropdown(
            function () {
                let options = []
                let components = JSON.parse(localStorage.getItem('applab_componentList'))
                components
                    .filter(comp => comp.c_type === k_textInputid)
                    .map(comp => options.push([comp.name, comp._id]))
                return options
            }
        ), 'textInput_input')
})

Blockly.Extensions.register('get_images', function () {
    this.getInput('image_input')
        .appendField(new Blockly.FieldDropdown(
            function () {
                let options = []
                let components = JSON.parse(localStorage.getItem('applab_componentList'))
                components
                    .filter(comp => comp.c_type === k_imageid)
                    .map(comp => options.push([comp.name, comp._id]))
                return options
            }
        ), 'image_input')
})

Blockly.Extensions.register('get_data_components', function () {
    this.getInput('dataC_input')
        .appendField(new Blockly.FieldDropdown(
            function () {
                let options = []
                let components = JSON.parse(localStorage.getItem('applab_componentList'))
                components
                    .filter(comp => comp.c_type === k_dataid)
                    .map(comp => options.push([comp.name, comp._id]))
                return options
            }
        ))
}, 'dataC_input')

Blockly.Extensions.register('get_tables', function () {
    this.getInput('table_input')
        .appendField(new Blockly.FieldDropdown(
            function () {
                let options = []
                let tables = JSON.parse(localStorage.getItem('applab_myTables'))
                tables.map(t => options.push([t.name, t.id]))
                return options
            }
        ))
}, 'table_input')

// Init blocks

Blockly.Blocks['when_do_screen'] = {
    init: function () {
        this.jsonInit(whenDoScreen)
    }
};

Blockly.Blocks['al_screen_getter'] = {
    init: function () {
        this.jsonInit(getterScreenBlock)
    }
}

Blockly.Blocks['al_screen_setter'] = {
    init: function () {
        this.jsonInit(setterScreenBlock)
    }
}

Blockly.Blocks['al_move_screen'] = {
    init: function () {
        this.jsonInit(navigateToAnotherScreen)
    }
}

Blockly.Blocks['when_do_buttons'] = {
    init: function () {
        this.jsonInit(whenDoButtons)
    }
};

Blockly.Blocks['al_button_getter'] = {
    init: function () {
        this.jsonInit(getterBlockButton)
    }
};

Blockly.Blocks['al_button_setter'] = {
    init: function () {
        this.jsonInit(setterBlockButton)
    }
};

Blockly.Blocks['al_label_getter'] = {
    init: function () {
        this.jsonInit(labelGetterBlock)
    }
}

Blockly.Blocks['al_label_setter'] = {
    init: function () {
        this.jsonInit(labelSetterBlock)
    }
}

Blockly.Blocks['al_text_input_getter'] = {
    init: function () {
        this.jsonInit(textInputGetterBlock)
    }
}

Blockly.Blocks['al_text_input_setter'] = {
    init: function () {
        this.jsonInit(textInputSetterBlock)
    }
}

Blockly.Blocks["al_image_getter"] = {
    init: function () {
        this.jsonInit(imageGetterBlock)
    }
}

Blockly.Blocks["al_image_setter"] = {
    init: function () {
        this.jsonInit(imageSetterBlock)
    }
}

Blockly.Blocks['set_color'] = {
    init: function () {
        this.jsonInit(setColor)
    }
}

Blockly.Blocks['set_text_input'] = {
    init: function () {
        this.jsonInit(setTextInput)
    }
}

Blockly.Blocks['al_numerical_input'] = {
    init: function () {
        this.jsonInit(setNumberInput)
    }
}

Blockly.Blocks['al_boolean'] = {
    init: function () {
        this.jsonInit(setBooleanInput)
    }
}

Blockly.Blocks['al_keyboard_type_input'] = {
    init: function () {
        this.jsonInit(setKeyboardType)
    }
}

Blockly.Blocks['al_screen_justification'] = {
    init: function () {
        this.jsonInit(setScreenJustification)
    }
}

Blockly.Blocks['al_screen_alignment'] = {
    init: function () {
        this.jsonInit(setScreenAlignment)
    }
}

Blockly.Blocks['al_insert_table_row'] = {
    init: function () {
        this.jsonInit(insertTableRow)
    }
}

Blockly.Blocks['al_get_table_row'] = {
    init: function () {
        this.jsonInit(getTableRow)
    }
}

Blockly.Blocks['al_where_row_column'] = {
    init: function () {
        this.jsonInit(whereRowColumn)
    }
}

Blockly.Blocks['al_next_row'] = {
    init: function () {
        this.jsonInit(nextRow)
    }
}

Blockly.Blocks['al_column_name'] = {
    init: function () {
        this.jsonInit(columnName)
    }
}

// [
//     {
//         "type": "al_listviewer_do",
//         "message0": "%1 %2 %3 %4 %5 %6 %7 %8",
//         "args0": [
//             {
//                 "type": "field_label_serializable",
//                 "name": "when",
//                 "text": "when"
//             },
//             {
//                 "type": "field_dropdown",
//                 "name": "list_viewer_options",
//                 "options": [
//                     [
//                         "list_viewer1",
//                         "list_viewer_1"
//                     ],
//                     [
//                         "list_viewer2",
//                         "list_viewer_2"
//                     ],
//                     [
//                         "list_viewer3",
//                         "list_viewer_3"
//                     ]
//                 ]
//             },
//             {
//                 "type": "field_dropdown",
//                 "name": "action_options",
//                 "options": [
//                     [
//                         "item_click",
//                         "item_click"
//                     ]
//                 ]
//             },
//             {
//                 "type": "input_dummy"
//             },
//             {
//                 "type": "input_value",
//                 "name": "item_input"
//             },
//             {
//                 "type": "input_value",
//                 "name": "index_input"
//             },
//             {
//                 "type": "field_label_serializable",
//                 "name": "do",
//                 "text": "do"
//             },
//             {
//                 "type": "input_statement",
//                 "name": "do"
//             }
//         ],
//         "colour": 210,
//         "tooltip": "Determines what to be done on list viewer click",
//         "helpUrl": ""
//     },
//     {
//         "type": "al_list_viewer_setter",
//         "message0": "%1 %2 set %3 to %4",
//         "args0": [
//             {
//                 "type": "field_label_serializable",
//                 "name": "for",
//                 "text": "for"
//             },
//             {
//                 "type": "field_dropdown",
//                 "name": "set_list_viewer options",
//                 "options": [
//                     [
//                         "list_viewer1",
//                         "list_viewer1"
//                     ],
//                     [
//                         "list_viewer2",
//                         "list_viewer2"
//                     ],
//                     [
//                         "list_viewer3",
//                         "list_viewer3"
//                     ]
//                 ]
//             },
//             {
//                 "type": "field_dropdown",
//                 "name": "text_input_properties",
//                 "options": [
//                     [
//                         "text items",
//                         "text items"
//                     ],
//                     [
//                         "height",
//                         "height"
//                     ],
//                     [
//                         "show arrow",
//                         "show_arrow"
//                     ]
//                 ]
//             },
//             {
//                 "type": "input_value",
//                 "name": "set list_viewer",
//                 "align": "RIGHT"
//             }
//         ],
//         "inputsInline": false,
//         "previousStatement": null,
//         "nextStatement": null,
//         "colour": 330,
//         "tooltip": "Set list viewer properties",
//         "helpUrl": ""
//     },
//     {
//         "type": "al_list_viewer_getter",
//         "message0": "%1 %2 get %3",
//         "args0": [
//             {
//                 "type": "field_label_serializable",
//                 "name": "from",
//                 "text": "from"
//             },
//             {
//                 "type": "field_dropdown",
//                 "name": "get_list_viewer options",
//                 "options": [
//                     [
//                         "list_viewer1",
//                         "list_viewer1"
//                     ],
//                     [
//                         "list_viewer2",
//                         "list_viewer2"
//                     ],
//                     [
//                         "list_viewer3",
//                         "list_viewer3"
//                     ]
//                 ]
//             },
//             {
//                 "type": "field_dropdown",
//                 "name": "list_viewer_properties",
//                 "options": [
//                     [
//                         "text items",
//                         "text items"
//                     ],
//                     [
//                         "height",
//                         "height"
//                     ],
//                     [
//                         "show arrow",
//                         "show_arrow"
//                     ]
//                 ]
//             }
//         ],
//         "inputsInline": false,
//         "output": null,
//         "colour": 330,
//         "tooltip": "Get list viewer properties",
//         "helpUrl": ""
//     },
//     {
//         "type": "al_row_setter",
//         "message0": "%1 %2 set %3 to %4",
//         "args0": [
//             {
//                 "type": "field_label_serializable",
//                 "name": "for",
//                 "text": "for"
//             },
//             {
//                 "type": "field_dropdown",
//                 "name": "set_row options",
//                 "options": [
//                     [
//                         "row1",
//                         "row1"
//                     ],
//                     [
//                         "row2",
//                         "row2"
//                     ],
//                     [
//                         "row3",
//                         "row3"
//                     ]
//                 ]
//             },
//             {
//                 "type": "field_dropdown",
//                 "name": "screen_properties",
//                 "options": [
//                     [
//                         "background colour",
//                         "background_colour"
//                     ],
//                     [
//                         "background picture",
//                         "background_picture"
//                     ],
//                     [
//                         "background picture resize mode",
//                         "background_picture_resize_mode"
//                     ],
//                     [
//                         "justification",
//                         "justification"
//                     ],
//                     [
//                         "alignment",
//                         "alignment"
//                     ],
//                     [
//                         "scrollable",
//                         "scrollable"
//                     ]
//                 ]
//             },
//             {
//                 "type": "input_value",
//                 "name": "set row",
//                 "align": "RIGHT"
//             }
//         ],
//         "inputsInline": false,
//         "previousStatement": null,
//         "nextStatement": null,
//         "colour": 60,
//         "tooltip": "Set Row properties",
//         "helpUrl": ""
//     },
//     {
//         "type": "al_row_getter",
//         "message0": "%1 %2 get %3",
//         "args0": [
//             {
//                 "type": "field_label_serializable",
//                 "name": "from",
//                 "text": "from"
//             },
//             {
//                 "type": "field_dropdown",
//                 "name": "set_row options",
//                 "options": [
//                     [
//                         "row1",
//                         "row1"
//                     ],
//                     [
//                         "row2",
//                         "row2"
//                     ],
//                     [
//                         "row3",
//                         "row3"
//                     ]
//                 ]
//             },
//             {
//                 "type": "field_dropdown",
//                 "name": "row_properties",
//                 "options": [
//                     [
//                         "background colour",
//                         "background_colour"
//                     ],
//                     [
//                         "background picture",
//                         "background_picture"
//                     ],
//                     [
//                         "background picture resize mode",
//                         "background_picture_resize_mode"
//                     ],
//                     [
//                         "justification",
//                         "justification"
//                     ],
//                     [
//                         "scrollable",
//                         "scrollable"
//                     ],
//                     [
//                         "scrollable",
//                         "scrollable"
//                     ],
//                     [
//                         "height",
//                         "height"
//                     ],
//                     [
//                         "weight",
//                         "weight"
//                     ]
//                 ]
//             }
//         ],
//         "inputsInline": false,
//         "output": null,
//         "colour": 60,
//         "tooltip": "Get Row properties",
//         "helpUrl": ""
//     },
//     {
//         "type": "al_image_input",
//         "message0": "%1",
//         "args0": [
//             {
//                 "type": "field_dropdown",
//                 "name": "image_options",
//                 "options": [
//                     [
//                         {
//                             "src": "https://www.gstatic.com/codesite/ph/images/star_on.gif",
//                             "width": 15,
//                             "height": 15,
//                             "alt": "*"
//                         },
//                         "image1"
//                     ],
//                     [
//                         {
//                             "src": "https://www.gstatic.com/codesite/ph/images/star_on.gif",
//                             "width": 15,
//                             "height": 15,
//                             "alt": "*"
//                         },
//                         "image2"
//                     ],
//                     [
//                         {
//                             "src": "https://www.gstatic.com/codesite/ph/images/star_on.gif",
//                             "width": 15,
//                             "height": 15,
//                             "alt": "*"
//                         },
//                         "image3"
//                     ]
//                 ]
//             }
//         ],
//         "output": null,
//         "colour": 210,
//         "tooltip": "",
//         "helpUrl": ""
//     },
//     {
//         "type": "al_list_viewer_item",
//         "message0": "%1",
//         "args0": [
//             {
//                 "type": "field_label_serializable",
//                 "name": "item",
//                 "text": "item"
//             }
//         ],
//         "output": null,
//         "colour": 330,
//         "tooltip": "",
//         "helpUrl": ""
//     },
//     {
//         "type": "al_list_viewer_index",
//         "message0": "%1",
//         "args0": [
//             {
//                 "type": "field_label_serializable",
//                 "name": "index",
//                 "text": "index"
//             }
//         ],
//         "output": null,
//         "colour": 330,
//         "tooltip": "",
//         "helpUrl": ""
//     },
//     {
//         "type": "al_list_viewer_list",
//         "message0": "%1",
//         "args0": [
//             {
//                 "type": "field_label_serializable",
//                 "name": "index",
//                 "text": "index"
//             }
//         ],
//         "output": null,
//         "colour": 330,
//         "tooltip": "",
//         "helpUrl": ""
//     },
//     {
//         "type": "al_screen_bg_resize_mode",
//         "message0": "%1 %2",
//         "args0": [
//             {
//                 "type": "field_label_serializable",
//                 "name": "bg_resize_mode",
//                 "text": "resize mode"
//             },
//             {
//                 "type": "field_dropdown",
//                 "name": "reside_mode_options",
//                 "options": [
//                     [
//                         "cover",
//                         "cover"
//                     ],
//                     [
//                         "contain",
//                         "contain"
//                     ],
//                     [
//                         "center",
//                         "center"
//                     ],
//                     [
//                         "repeat",
//                         "repeat"
//                     ]
//                 ]
//             }
//         ],
//         "output": null,
//         "colour": 60,
//         "tooltip": "Used to resize images",
//         "helpUrl": ""
//     }
// ]