import React from 'react'
// Styles
import styles from './fourOfour.module.css'

const Four0four = props => {
    return <div className={styles.container}>
        <div className={styles.smileyGrid}>

            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>

            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>

            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>

            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>

            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>

            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>

            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>

            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>

            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>

            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>

            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>

            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>

            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>

            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" style={{ color: "black" }} aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>

            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
        </div>
        <p className={styles.errorMessage}>Sorry! This page doesn't exist.<br />Go to <a href={window.location.origin}>main page</a>.</p>
    </div>
}

export default Four0four