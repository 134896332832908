import React, { Component } from 'react'
// import Cookie from 'js-cookie'
// import socketIOClient from 'socket.io-client'
// Routes
import { Link } from "react-router-dom";
// Redux
import { connect } from 'react-redux'
import { editProject } from '../../../redux/actions'
// Api
// import { buildProject, teURL } from '../../../apis/constants'
// import teAPI from '../../../apis/template-engine'
// Components
// Static
import itklogo from '../../../static/images/itklogo.svg'
// import publish from '../../../static/images/publishBlue.svg'
// import download from '../../../static/images/downloadBlue.svg'
// import progress from '../../../static/images/switchBlue.svg'
// import back from '../../../static/images/backBlue.svg'
// import logout from '../../../static/images/logoutRed.svg'
// import download from '../../../static/images/downloadBlue.svg'
// import duplicate from '../../../static/images/duplicateBlue.svg'
// import share from '../../../static/images/shareBlue.svg'
// Styles
import styles from './header.module.css'

class Header extends Component {

    // constructor(props) {
    // super(props)
    // this.socket_client = socketIOClient(beURL)
    // this.state = {
    //     published: false,
    //     showProgress: false,
    //     progress: 0,
    //     progressDescription: null
    // }
    // }

    // componentDidMount() {
    // this.socket_client.on('connection')
    // this.socket_client.on('getProgress', (data) => {
    //     this.setState({
    //         progress: parseInt(data.status),
    //         progressDescription: data.description,
    //     })
    // })
    // }

    // onCheckProgress = () => {
    // this.socket_client.emit('checkProgress', { projectId: this.props.projectid })
    // this.setState(prevState => {
    //     return { showProgress: !prevState.showProgress }
    // })
    // }

    // onPublish = () => {
    //     let token = Cookie.get('token')
    //     if (window.confirm('Are you sure you want to publish this project?')) {
    //         teAPI
    //             .get(buildProject + this.props.projectid, {
    //                 params: {
    //                     token
    //                 }
    //             })
    //             .then(response => {
    //                 // console.log(response)
    //                 if (response.status === 200) {
    //                     // this.setState({ published: true })
    //                     // this.onCheckProgress()
    //                     alert('Your request has been registered with us.\nThank you for using ITK App Lab.\nTo check the latest update, see progress.\n')
    //                     window.open(teURL + response.data, 'tab')
    //                 } else {
    //                     alert('Build error. Status code: ' + response.status)
    //                 }
    //             })
    //             .catch(error => alert('Build error message: ' + error.message))
    //     }
    // }

    onDownload = (fromProgress) => {
        // if (fromProgress) {
        //     window.open(this.state.progressDescription, 'tab')
        //     let project = {
        //         pid: this.props.projectid,
        //         url: this.state.progressDescription
        //     }
        //     this.props.editProject(project)
        // }
        // else {
        window.open(this.props.downloadLink, 'tab')
        // }
    }

    onLogout = () => {
        window.location.replace(process.env.REACT_APP_AUTH + 'logout')
    }

    render() {

        // let progressMessage
        // if (this.state.progress === 0) { progressMessage = <Fragment><i className="fa fa-info-circle"></i> (1/7) Publish process will begin soon</Fragment> }
        // else if (this.state.progress === 10) { progressMessage = <Fragment><i className="fa fa-spinner fa-spin"></i> (2/7) Starting to publish</Fragment> }
        // else if (this.state.progress === 15) { progressMessage = <Fragment><i className="fa fa-spinner fa-spin"></i> Queued for module install</Fragment> }
        // else if (this.state.progress === 20) { progressMessage = <Fragment><i className="fa fa-spinner fa-spin"></i> (3/7) Installing necessary modules</Fragment> }
        // else if (this.state.progress === 25) { progressMessage = <Fragment><i className="fa fa-spinner fa-spin"></i> Queued for APK build</Fragment> }
        // else if (this.state.progress === 30) { progressMessage = <Fragment><i className="fa fa-spinner fa-spin"></i> (4/7) Exporting the application bundles</Fragment> }
        // else if (this.state.progress === 40) { progressMessage = <Fragment><i className="fa fa-spinner fa-spin"></i> (5/7) Packaging bundles to create APK</Fragment> }
        // else if (this.state.progress === 50) { progressMessage = <Fragment><i className="fa fa-spinner fa-spin"></i> (6/7) Moving APK to cloud</Fragment> }
        // else if (this.state.progress === 60) { progressMessage = <Fragment><i className="fa fa-check-circle"></i> Download the <button onClick={() => this.onDownload(true)}>apk</button>.</Fragment> }
        // else { progressMessage = <Fragment><i className="fa fa-exclamation-triangle error" title={this.state.progressDescription}></i></Fragment> }

        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.logoArea}>
                        <img src={itklogo} className={styles.itkLogo} alt="ITK Logo" />
                    </div>
                    <div className={styles.navItem}>
                        {!!this.props.downloadLink
                            && <li onClick={() => this.onDownload(false)}>{/*<img src={download} alt="download" />*/}Download</li>}
                        {/* {this.state.published
                            ? <Fragment><li className={styles.progressListItem} onClick={this.onCheckProgress}><img src={progress} alt="progress" />Progress</li>
                                {this.state.showProgress
                                    && <div className={styles.progressArea} onClick={this.onCheckProgress}>
                                        <div className={styles.progressContainer}>
                                            {progressMessage}
                                        </div>
                                    </div>}
                            </Fragment> */}
                        {/* :  */}
                        {/* {!!this.props.projectid
                            && <li onClick={this.onPublish}><img src={publish} alt="publish" />Publish</li>} */}
                        {/* } */}
                        {/* <li><img src={duplicate} alt="duplicate" />Duplicate</li> */}
                        {/* <li><img src={share} alt="share" />Share</li> */}
                        {!!this.props.projectid || this.props.table
                            ? <Link to="/"><li title="Go back" className={styles.links}> {/* <img src={back} alt="back" />  */}
                                {!!this.props.projectid ? 'My Projects' : 'Go back'}</li></Link>
                            : null}
                        <li title="Logout" className={styles.links} onClick={this.onLogout}>{/*<img src={logout} alt="logout" />*/}Logout</li> {/* className={styles.red} */}
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        editProject: (project) => dispatch(editProject(project)),
    }
}

export default connect(null, mapDispatchToProps)(Header)