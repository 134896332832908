import React, { Component, Fragment } from 'react'
// Redux
import { connect } from 'react-redux'
import * as actions from '../../redux/actions/index'
// Components
import Property from './property/property'
import { PropertyCategory } from './propertyCategory/propertyCategory'
// styles
import styles from './propertyList.module.css'
// static files
// import rename from '../../static/images/editBlack.svg'
import deleteIcon from '../../static/images/deleteThinBlack.svg'
import deleteIconHover from '../../static/images/deleteThinBlue.svg'

class PropertyList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            componentName: this.props.compName,
            componentNameInput: null,
            change: false,
            changeError: null
        }
        this.deleteChildIds = []
    }

    static getDerivedStateFromProps(props, state) {
        // console.log('getting state from props')
        if (props.compName !== state.componentName) {
            return {
                ...state,
                componentName: props.compName
            }
        } else {
            return state
        }
    }

    getChildIds = (compId) => {
        let component = this.props.components.find(c => c._id === compId)
        if (!(this.deleteChildIds.includes(component._id))) this.deleteChildIds.push(component._id)
        if (component.type === 'advanced' && component.children.length !== 0) {
            component.children.map(child => this.getChildIds(child))
        }
    }

    onChangeProperty = (componentId, name, value) => {
        let change_property = {
            cid: componentId,
            property_name: name,
            property_value: value,
        }
        this.props.changeProperty(change_property)
        this.props.onChange(this.props.screenId, false)
    }

    onRenameComponent = () => {
        if (!!this.state.componentNameInput) {
            this.setState({ change: false, changeError: null })
            let component = {
                "cid": this.props.id,
                "new_name": this.state.componentNameInput
            }
            this.props.renameComponent(component)
            this.props.onChange(this.props.screenId, false)
        } else {
            this.setState({ changeError: 'Please enter a valid name.' })
        }
    }

    onDeleteComponent = (componentId, parent) => {
        if (componentId === this.props.project[this.props.projectId].root_screen) {
            alert('You cannot delete the root screen of a project.')
        }
        else if (componentId === this.props.project[this.props.projectId].current_screen) {
            if (window.confirm('Deleting this screen will also delete its children. You will be redirected to Screen1.\nAre you sure you want to delete this screen?')) {
                this.getChildIds(componentId)
                this.props.deleteScreen(this.props.projectId, componentId, this.deleteChildIds)
                this.props.onChange(this.props.rootScreen, true)
            }
        } else {
            if (this.props.isAdvanced) {
                if (window.confirm('This seems to be a container component. Deleting it will also delete its children.\nAre you sure you want to delete?')) {
                    this.getChildIds(componentId)
                    this.props.deleteChildren(parent, componentId, this.deleteChildIds)
                    this.props.onChange(this.props.screenId, true)

                }
            } else {
                if (window.confirm('Are you sure you want to delete?')) {
                    this.props.deleteComponent(componentId, parent)
                    this.props.onChange(this.props.screenId, true)
                }
            }
        }
    }

    onEditClick = () => {
        this.setState({ change: true, componentNameInput: this.state.componentName })
    }

    onEditClose = () => {
        this.setState({ change: false, changeError: null })
    }

    onEdit = e => {
        this.setState({ componentNameInput: e.target.value })
    }

    render() {
        let uniquePropertiesMount = []
        Object.keys(this.props.properties).map(propName => {
            if (typeof this.props.properties[propName] === 'object') {
                if (uniquePropertiesMount.indexOf(this.props.properties[propName].category) === -1) {
                    uniquePropertiesMount.push(this.props.properties[propName].category)
                }
            }
            return null
        })
        let categoryProperties = []
        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.nameArea}>
                        {this.state.change
                            ? <input className={styles.nameHeaderChange} value={this.state.componentNameInput} onChange={this.onEdit} disabled={!this.state.change} autoFocus />
                            : <h1 className={styles.nameHeader} onClick={this.onEditClick}>{this.state.componentName}</h1>
                        }
                        <button onClick={() => this.onDeleteComponent(this.props.id, this.props.parent)}>
                            <img className={styles.deleteIcon} src={deleteIcon} alt="delete"
                                onMouseOver={(e) => e.currentTarget.src = deleteIconHover}
                                onMouseLeave={(e) => e.currentTarget.src = deleteIcon} />
                        </button>
                    </div>
                    <div className={styles.nameActionButtons}>
                        {this.state.change
                            && <Fragment>
                                <button onClick={this.onRenameComponent}><i className="fa fa-floppy-o" /></button>
                                <button onClick={this.onEditClose}><i className="fa fa-times" /></button>
                            </Fragment>}
                    </div>
                    {!!this.state.changeError && <div className="error">{this.state.changeError}</div>}
                </div>
                {Array.isArray(uniquePropertiesMount) && uniquePropertiesMount.length !== 0
                    ? uniquePropertiesMount.map(cat => {
                        categoryProperties = []
                        Object.keys(this.props.properties).filter(propertyName => this.props.properties[propertyName].category === cat).map(propertyName => { categoryProperties.push({ ...this.props.properties[propertyName], name: propertyName }); return null }) // 
                        if (cat !== undefined || cat !== null) {
                            return <PropertyCategory key={cat} id={this.props.id} cTypeId={this.props.cTypeId} edit={this.onChangeProperty} categoryName={cat} categoryProperties={categoryProperties} />
                        } else {
                            return <PropertyCategory key={cat} id={this.props.id} cTypeId={this.props.cTypeId} edit={this.onChangeProperty} categoryName="Miscellaneous" categoryProperties={categoryProperties} />
                        }
                    })
                    : Object.keys(this.props.properties).map(propertyName => {
                        return <Property key={propertyName}
                            id={this.props.id}
                            name={propertyName}
                            value={this.props.properties[propertyName]}
                            cTypeId={this.props.cTypeId}
                            edit={this.onChangeProperty} />
                    })
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    project: state.projects.projects,
    components: state.component.components,
    compLoading: state.component.fetchLoading,
    compError: state.component.fetchError,
})

const mapDispatchToProps = dispatch => ({
    changeProperty: (newValues) => dispatch(actions.changeProperty(newValues)),
    renameComponent: (component) => dispatch(actions.renameComponent(component)),
    deleteComponent: (componentId, parent) => dispatch(actions.deleteComponent(componentId, parent)),
    deleteChildren: (parent, componentId, childId) => dispatch(actions.deleteChildren(parent, componentId, childId)),
    deleteScreen: (parent, screenId, childId) => dispatch(actions.deleteScreen(parent, screenId, childId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PropertyList)