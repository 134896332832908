import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, HashRouter, Switch } from "react-router-dom";
import store from './redux/store'

// Components
import styles from './index.module.css'
import ProjectListScreen from './containers/ProjectList/ProjectListScreen';
import ProjectScreen from './containers/Project/ProjectScreen'
import ProtectedRoute from './components/navigation/ProtectedRoute/ProtectedRoute';
import Four0four from './containers/Error/4xx/fourOfour';
import Firebase, { FirebaseContext } from './components/firebase';
import TableScreen from './containers/Table/Table';


ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <div className={styles.container}>
                <FirebaseContext.Provider value={new Firebase()} >
                    <Switch>
                        <ProtectedRoute path="/" exact component={ProjectListScreen} />
                        <ProtectedRoute path="/project/:pid" exact component={ProjectScreen} />
                        <ProtectedRoute path="/table/:tid" exact component={TableScreen} />
                        <Route path="/**" exact component={Four0four} />
                    </Switch>
                </FirebaseContext.Provider>
            </div>
        </HashRouter>
    </Provider>,
    document.getElementById('root')
);
