import React from 'react'
import { FirebaseContext } from '../../components/firebase'
// Components
import Project from './Project'

const ProjectScreen = () => {
    return <FirebaseContext.Consumer>
        {firebase => <Project firebase={firebase} />}
    </FirebaseContext.Consumer>
}

export default ProjectScreen