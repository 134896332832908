import React, { Component, Fragment } from 'react'
import Cookie from 'js-cookie'
// Redux
import { connect } from 'react-redux';
import * as actions from '../../redux/actions'
// API
import applab from '../../apis/applab'
import { getMetaComponents } from '../../apis/constants'
// Router
import { withRouter } from 'react-router'
// Styles
import styles from './Project.module.css'
import projectListStyles from '../ProjectList/ProjectList.module.css'
// Components
import Header from '../../components/navigation/header/header'
import { metaComponent as MetaComponent } from '../../components/metaComponent/metaComponent'
import ComponentList from '../../components/componentList/componentList'
import Preview from '../../components/preview/preview';
import PropertyList from '../../components/propertyList/propertyList';
import { Modal } from '../../components/ui/modal/modal';
// Constants
import { k_screenid } from '../../constants'
// Statics
import edit from '../../static/images/editGray.svg'
// Blockly
import BlocklyComponent from '../../components/blockly'
import '../../blocks/customBlocks'
import '../../blocks/generator'

class Project extends Component {

  constructor(props) {
    super(props)
    this.componentList = []
    document.title = 'ITK - My Project'
    this.state = {
      // project variables
      projectId: null,
      isProjectEdit: false,
      project: {
        name: null,
        description: null,
        current_screen: null
      },
      // meta component variables
      metaComponent: [],
      uniqueMetaCategories: [],
      metaComponentLoading: false,
      metaComponentError: null,
      // class variables
      selectedComponent: null,
      componentProperties: {},
      componentsOfSelectedScreen: [],
      draggedComponents: [],
      showDesignBlock: true,
      initialToolbar: [],
      // firebase tables
      myTables: []
    }
    this.userid = null
  }

  componentDidMount() {
    this.userid = Cookie.get('userId')
    this.setState({
      projectId: this.props.match.params.pid,
      metaComponentLoading: true
    })
    this.props.fetchComponents(this.props.match.params.pid)
    this.props.getOneProject(this.props.match.params.pid)
    let mydb = this.props.firebase.db.ref('/' + this.userid + '/')
    mydb.once('value', snap => {
      let tables = snap.val() || {}
      let userTables = []
      Object.keys(tables).map(ut => {
        userTables.push(tables[ut])
        return null
      })
      this.setState({
        myTables: userTables
      })
    })
    applab
      .get(getMetaComponents)
      .then(response => {
        if (response.status >= 200) {
          let uniqueCategories = []
          response.data.components
            .filter(comp => comp.c_type !== 'screen')
            .map(metaC => {
              if (uniqueCategories.indexOf(metaC.category) === -1) {
                uniqueCategories.push(metaC.category)
              }
              return null
            })
          this.setState({
            metaComponent: response.data.components.filter(comp => comp.c_type !== 'screen'),
            uniqueMetaCategories: uniqueCategories,
            metaComponentLoading: false,
          })
        } else {
          this.setState({
            metaComponentLoading: false,
            metaComponentError: response.data.message
          })
        }
      })
      .catch(error => this.setState({ metaComponentLoading: false, metaComponentError: error.message }))
  }

  componentDidUpdate() {
    if (Object.keys(this.props.project).length !== 0) {
      let oneProject = {}
      Object.keys(this.props.project).map(projId => oneProject = this.props.project[projId])
      document.title = 'ITK - ' + oneProject.name
      let current_screen = this.state.project.current_screen || oneProject.current_screen
      if (this.state.componentsOfSelectedScreen.length === 0 && Array.isArray(this.props.components) && this.props.components.length !== 0) {
        this.props.components.filter(comp => comp._id === current_screen || comp.parent === current_screen).map(comp => {
          this.getComponent(comp._id)
          return null
        })
        this.onSelectComponent(current_screen)
      }
    }
  }

  showDesignBlock = (bool) => {
    this.setState({
      showDesignBlock: bool,
    })
  }

  onAddScreen = () => {
    this.props.addComponent(this.state.projectId, 'screen', null, null)
    this.setState({ selectedComponent: null })
  }

  onChangeActiveScreen = e => {
    this.getComponentsOfCurrentScreen(e.target.value, true)
    let project = {
      "pid": this.state.projectId,
      "current_screen": e.target.value,
    }
    this.props.editProject(project)
  }

  onChangeProjectDetailsClick = () => {
    let oneProject = {}
    Object.keys(this.props.project).map(projId => oneProject = this.props.project[projId])
    this.setState(prevState => {
      return {
        isProjectEdit: !prevState.isProjectEdit,
        project: {
          name: oneProject.name,
          description: oneProject.description
        }
      }
    })
  }

  onChangeProjectDetails = e => {
    this.setState({
      project: {
        ...this.state.project,
        [e.target.name]: e.target.value
      }
    })
  }

  onProjectDetailsUpdate = () => {
    let project = {
      pid: this.state.projectId,
      name: this.state.project.name,
      description: this.state.project.description
    }
    this.props.editProject(project)
    this.setState(prevState => { return { isProjectEdit: !prevState.isProjectEdit } })
  }

  onSelectComponent = (componentId) => {
    let componentProp = this.props.components.find(comp => comp._id === componentId)
    if (!!componentProp) {
      this.setState({
        selectedComponent: componentId,
        componentProperties: componentProp.properties
      })
    }
  }

  getComponent = (compId) => {
    let existingComponents = this.componentList
    if (existingComponents.find(comp => comp._id === compId)) {
      // do nothing
    } else {
      existingComponents.push(this.props.components.find(comp => comp._id === compId))
    }
    this.props.components.filter(comp => comp._id === compId || comp.parent === compId).map(comp => {
      if (comp.type === 'advanced' && comp.children.length !== 0) {
        comp.children.map(com => this.getComponent(com, existingComponents))
      }
      return null
    })
    this.setState({
      componentsOfSelectedScreen: this.componentList
    })
  }

  getComponentsOfCurrentScreen = (current_screen, reload) => {
    this.componentList = []
    this.setState({
      project: {
        ...this.state.project,
        current_screen: current_screen
      }
    }, () => {
      this.props.components.filter(comp => comp._id === current_screen || comp.parent === current_screen).map(comp => {
        this.getComponent(comp._id)
        return null
      })
      if (!!reload) {
        this.onSelectComponent(current_screen)
      }
    })
  }

  getDraggedChildIds = (compId) => {
    let component = this.props.components.find(c => c._id === compId)
    let childIds = this.state.draggedComponents
    if (!(childIds.includes(component._id))) {
      childIds.push(component._id)
      this.setState({ draggedComponents: childIds })
    }
    if (component.type === 'advanced' && component.children.length !== 0) {
      component.children.map(child => this.getDraggedChildIds(child))
    }
  }

  emptyDraggedChildIds = () => {
    this.setState({ draggedComponents: [] })
  }

  render() {
    let extensiveComponents = [
      ...this.state.componentsOfSelectedScreen.filter(c => c.c_type !== k_screenid),
      ...this.props.components.filter(c => c.c_type === k_screenid)
    ]
    localStorage.setItem('applab_componentList', JSON.stringify(extensiveComponents))
    localStorage.setItem('applab_myTables', JSON.stringify(this.state.myTables))
    let oneProject = {}
    Object.keys(this.props.project).map(projId => oneProject = this.props.project[projId])
    // let selectedComponent = this.props.components.find(c => c._id === this.state.selectedComponent)
    localStorage.setItem('applab_currentScreen', oneProject.current_screen)
    let thisScreen = this.props.components.find(c => c._id === oneProject.current_screen)
    return (
      <div>
        {this.state.isProjectEdit
          && <Modal show={this.state.isProjectEdit} no={this.onChangeProjectDetailsClick}>
            <div className={projectListStyles.addProjectModal}>
              <h1>Edit Project</h1>
              <div>
                <label>Name</label>
                <input
                  type="textbox"
                  maxLength="50"
                  value={this.state.project.name}
                  name="name"
                  pattern="[a-zA-Z0-9_\s]+"
                  title="No special characters"
                  onChange={this.onChangeProjectDetails}
                  autoFocus />
                <sub className={styles.specialSubMessages}>No special characters except underscore(_). Spaces allowed.</sub>
              </div>
              <div>
                <label>Description</label>
                <input
                  type="textbox"
                  maxLength="60"
                  value={this.state.project.description}
                  name="description"
                  onChange={this.onChangeProjectDetails} />
                <sub className={styles.specialSubMessages}>Only 60 characters.</sub>
              </div>
              <button onClick={this.onProjectDetailsUpdate}>Update</button>
            </div>
          </Modal>}
        <Header projectid={this.state.projectId} downloadLink={oneProject.url} /> {/*downloadLink={oneProject.downloadLink}*/}
        <div className={styles.container}>
          <div className={styles.actionButtons}>
            {this.props.projLoading || this.props.compLoading
              ? <div className="loading"><i className="fa fa-spinner fa-spin"></i> Loading</div>
              : this.props.projError !== null
                ? <div className="error">Project fetch error: {this.props.projError}</div>
                : this.props.compError !== null
                  ? <div className="error">Component fetch error: {this.props.compError}</div>
                  : <div className={styles.screenActionButtons}>
                    {this.state.showDesignBlock
                      && <Fragment>
                        <select
                          value={oneProject.current_screen}
                          onChange={this.onChangeActiveScreen}>
                          {this.props.components
                            .filter(comp => comp.c_type === k_screenid)
                            .map(comp => <option
                              key={comp._id}
                              value={comp._id}>
                              {comp.name}
                            </option>)}
                        </select>
                        <button onClick={this.onAddScreen}>Add Screen</button>
                        {this.props.projEditLoading
                          ? <div className="loading"><i className="fa fa-spinner fa-spin"></i> Editing</div>
                          : this.props.projEditError !== null
                          && <div className="error">{this.props.projEditError}</div>}
                      </Fragment>}
                  </div>}
            <div className={styles.projectName}>{'name' in oneProject && <h1 onClick={this.onChangeProjectDetailsClick}>{oneProject.name} <img src={edit} alt="Edit" /></h1>}</div>
            <div className={styles.designAndBlock}>
              <button
                onClick={() => this.showDesignBlock(true)}
                className={this.state.showDesignBlock ? styles.designButton : null}>
                Design
                </button>
              <button
                onClick={() => this.showDesignBlock(false)}
                className={this.state.showDesignBlock ? null : styles.designButton}
                disabled={this.state.componentsOfSelectedScreen.length === 0}>
                Blocks
                </button>
            </div>
          </div>
          <br />
          {this.state.showDesignBlock
            ? <div className={styles.designPage}>
              <div className={styles.column} id={styles.metaComponentList}>
                <h4 className={styles.columnHeader}>ELEMENTS</h4>
                <div className={styles.metaComponentList}>
                  {process.env.REACT_APP_BACK_END === 'http://applabapi-stage.itkkids.xyz/'
                    && <div className={["error", styles.metaComponentError].join(' ')}><i className="fa fa-exclamation-triangle" title="Elements with no icon are under development and not for testing."></i></div>}
                  {this.state.metaComponentLoading
                    ? <div className="loading"><i className="fa fa-spinner fa-spin"></i></div>
                    : this.state.metaComponentError !== null
                      ? <div className="error">{this.state.metaComponentError}</div>
                      : this.state.uniqueMetaCategories.map(cat => {
                        if (cat !== undefined) {
                          return <details key={cat} open>
                            <summary>{cat}</summary>
                            {this.state.metaComponent.filter(metaC => metaC.category === cat)
                              .map(metaComp =>
                                <MetaComponent key={metaComp._id}
                                  id={metaComp._id}
                                  name={metaComp.displayName}
                                  c_type={metaComp.c_type}
                                  icon={metaComp.icon}
                                />)}
                          </details>
                        } else {
                          return <details key="miscellaneous" open>
                            <summary>Miscellaneous</summary>
                            {this.state.metaComponent.filter(metaC => metaC.category === cat)
                              .map(metaComp =>
                                <MetaComponent key={metaComp._id}
                                  id={metaComp._id}
                                  name={metaComp.displayName}
                                  c_type={metaComp.c_type}
                                  icon={metaComp.icon}
                                />)}
                          </details>
                        }
                      })
                  }
                </div>
              </div>
              <div className={styles.column} id={styles.previewScreenColumn}>
                <h4 className={styles.columnHeader}>VIEWER</h4>
                <div id={styles.previewScreen}>
                  <Preview
                    projectId={this.state.projectId}
                    screen={oneProject.current_screen}
                    selectedComponent={this.state.selectedComponent}
                    draggedChildIds={this.state.draggedComponents}
                    getChildIds={this.getDraggedChildIds}
                    emptyChildIds={this.emptyDraggedChildIds}
                    onSelect={this.onSelectComponent}
                    onChange={() => this.getComponentsOfCurrentScreen(oneProject.current_screen, true)} />
                </div>
              </div>
              <div className={styles.column} id={styles.componentList}>
                <h4 className={styles.columnHeader}>COMPONENTS</h4>
                <div className={styles.componentList}>
                  {this.props.addCLoading
                    ? <div className="loading"><i className="fa fa-spinner fa-spin"></i> Adding</div>
                    : this.props.addCError !== null
                    && <div className="error">Adding error: {this.props.addCError}</div>}
                  {this.props.editCLoading
                    ? <div className="loading"><i className="fa fa-spinner fa-spin"></i> Editing</div>
                    : this.props.editCError !== null
                    && <div className="error">Editing error: {this.props.editCError}</div>}
                  {this.props.deleteCLoading
                    ? <div className="loading"><i className="fa fa-spinner fa-spin"></i> Deleting</div>
                    : this.props.deleteCError !== null
                    && <div className="error">Deleting error: {this.props.deleteCError}</div>}
                  {this.props.renameLoading
                    ? <div className="loading"><i className="fa fa-spinner fa-spin"></i> Renaming</div>
                    : this.props.renameError !== null
                    && <div className="error">Renaming error: {this.props.renameError}</div>}
                  {this.props.compLoading
                    ? <div className="loading"><i className="fa fa-spinner fa-spin"></i> Fetching</div>
                    : this.props.compError !== null
                      ? <div className="error">Fetching error: {this.props.compError}</div>
                      : <ComponentList
                        projectId={this.state.projectId}
                        selectedComponent={this.state.selectedComponent}
                        draggedChildIds={this.state.draggedComponents}
                        onSelect={this.onSelectComponent}
                        getChildIds={this.getDraggedChildIds}
                        emptyChildIds={this.emptyDraggedChildIds}
                        screenId={oneProject.current_screen} />}
                </div>
              </div>
              <div className={styles.column}>
                <h4 className={styles.columnHeader}>PROPERTIES</h4>
                <div className={styles.properties}>
                  {this.state.selectedComponent === null
                    ? <div>Select a component to see/edit its properties</div>
                    : <div style={{ width: "100%" }}>
                      {this.props.addCLoading
                        ? <div className="loading"><i className="fa fa-spinner fa-spin"></i> Adding</div>
                        : this.props.addCError !== null
                        && <div className="error">Adding error: {this.props.addCError}</div>}
                      {this.props.editCLoading
                        ? <div className="loading"><i className="fa fa-spinner fa-spin"></i> Editing</div>
                        : this.props.editCError !== null
                        && <div className="error">Editing error: {this.props.editCError}</div>}
                      {this.props.deleteCLoading
                        ? <div className="loading"><i className="fa fa-spinner fa-spin"></i> Deleting</div>
                        : this.props.deleteCError !== null
                        && <div className="error">Deleting error: {this.props.deleteCError}</div>}
                      {this.props.renameLoading
                        ? <div className={styles.renameLoading}><i className="fa fa-spinner fa-spin"></i> Renaming</div>
                        : this.props.renameError !== null
                        && <div className="error">Renaming error: {this.props.renameError}</div>}
                      {this.props.compLoading
                        ? <div className="loading"><i className="fa fa-spinner fa-spin"></i> Fetching</div>
                        : this.props.compError !== null
                          ? <div className="error">Fetching error: {this.props.compError}</div>
                          // : Object.keys(selectedComponent).length === 0
                          //   ? <div className="loading"><i className="fa fa-spinner fa-spin"></i> Getting selected component</div>
                          : <PropertyList
                            id={this.state.selectedComponent}
                            projectId={this.state.projectId}
                            rootScreen={oneProject.root_screen}
                            screenId={oneProject.current_screen}
                            parent={this.props.components.find(c => c._id === this.state.selectedComponent).parent}
                            compName={this.props.components.find(c => c._id === this.state.selectedComponent).name}
                            cTypeId={this.props.components.find(c => c._id === this.state.selectedComponent).c_type}
                            isAdvanced={this.props.components.find(c => c._id === this.state.selectedComponent).type === 'advanced'}
                            properties={this.state.componentProperties}
                            onChange={this.getComponentsOfCurrentScreen} />}
                    </div>
                  }
                </div>
              </div>
            </div>
            : <div className={styles.blocksPage}>
              {this.props.compLoading
                ? <div className="loading"><i className="fa fa-spinner fa-spin"></i> Fetching</div>
                : this.props.compError !== null
                  ? <div className="error">Fetching error: {this.props.compError}</div>
                  : <BlocklyComponent
                    ref={e => this.simpleWorkspace = e}
                    readOnly={false}
                    initialXml={thisScreen.xml}
                    move={{
                      scrollbars: true,
                      drag: true,
                      wheel: true
                    }}
                    current_screen={oneProject.current_screen}
                    onUpdateXml={this.props.updateScreenXml}
                    onUpdateCode={this.props.updateScreenCode}
                  />}
              {/* } */}
            </div>
          }
        </div>
      </div >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    project: state.projects.projects,
    projLoading: state.projects.projectLoading,
    projError: state.projects.projectError,
    projEditLoading: state.projects.editLoading,
    projEditError: state.projects.editError,
    components: state.component.components,
    compLoading: state.component.fetchLoading,
    compError: state.component.fetchError,
    addCLoading: state.component.addLoading,
    addCError: state.component.addError,
    editCLoading: state.component.editLoading,
    editCError: state.component.editError,
    deleteCLoading: state.component.deleteLoading,
    deleteCError: state.component.deleteError,
    renameLoading: state.component.renameLoading,
    renameError: state.component.renameError,
    xmlLoading: state.component.xmlLoading,
    xmlError: state.component.xmlError,
    codeLoading: state.component.codeLoading,
    codeError: state.component.codeError,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOneProject: (projectId) => dispatch(actions.fetchOneProject(projectId)),
    editProject: (project) => dispatch(actions.editProject(project)),
    updateScreenCode: (updatedComponentCode) => dispatch(actions.updateCode(updatedComponentCode)),
    updateScreenCode_OnTheGo: (updatedComponentCode) => dispatch(actions.updateCode_OnTheGo(updatedComponentCode)),
    updateScreenXml: (updatedComponentXml) => dispatch(actions.updateXml(updatedComponentXml)),
    updateScreenXml_OnTheGo: (updatedComponent) => dispatch(actions.updateXml_OnTheGo(updatedComponent)),
    changeProperty: (newValues) => dispatch(actions.changeProperty(newValues)),
    addComponent: (projectId, componentType, parentId, pos) => dispatch(actions.addComponent(projectId, componentType, parentId, pos)),
    fetchComponents: (projectId) => dispatch(actions.fetchComponents(projectId)),
    renameComponent: (component) => dispatch(actions.renameComponent(component)),
    deleteComponent: (componentId, parent) => dispatch(actions.deleteComponent(componentId, parent)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Project));