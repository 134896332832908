import Cookie from 'js-cookie'
import axios from 'axios';
// Constants
import { beURL } from './constants' //daURL, postData

// let userid = Cookie.get('userId')
let token = Cookie.get('token')
// let token = process.env.REACT_APP_BACKEND_TOKEN
// let data = { userId: userid, session_id: token, itk_application_name: 'applab' }

const applab = axios.create({
    baseURL: beURL,
    headers: {
        Authorization: "Bearer " + token,
    }
});

// const dataApi = axios.create({
//     baseURL: daURL,
// })

// applab.interceptors.request.use(function (config) {
//     return config
// }, function (error) {
//     // console.log(error)
//     return Promise.reject(error)
// })

// applab.interceptors.response.use(function (response) {
//     if (response.config.method === 'post' || response.config.method === 'put' || response.config.method === 'POST' || response.config.method === 'PUT') {
//         console.log('applab.js: ', response.config.data)
//         let parsedData = JSON.parse(response.config.data)
//         data = { ...data, ...parsedData }
//         dataApi.post(postData, data).then(response => null).catch(error => null)
//     } else if (response.config.method === 'delete') {
//         // console.log('request delete')
//         let parsedData = JSON.parse(response.config.data)
//         let id = response.config.url.split('/')[response.config.url.split('/').length - 1]
//         if (id !== 'deleteChildren') {
//             data = { ...data, ...parsedData, cid: id }
//         } else {
//             data = { ...data, ...parsedData }
//         }
//         dataApi.post(postData, data).then(response => null).catch(error => null)
//     }
//     return response;
// }, function (error) {
//     // console.log(error.response)
//     // if ('response' in error && !!error.response && error.response.status === 403) {
//     //     // token has expired
//     // }
//     return Promise.reject(error)
// })

export default applab