import * as Blockly from 'blockly/core';
import 'blockly/javascript';

let specialProperties = ['text', 'type', 'name', 'placeholder', 'src', 'alt']

Blockly.JavaScript['when_do_screen'] = function (block) {
    var statements_do = Blockly.JavaScript.statementToCode(block, 'do');
    var code = `async componentDidMount() { 
` + statements_do + `
};
`
    return code;
}

Blockly.JavaScript['al_screen_getter'] = function (block) {
    var dropdown_set_screen_options = block.getFieldValue('current_screen_input');
    var dropdown_screen_properties = block.getFieldValue('screen_properties');
    // TODO: Assemble JavaScript into code variable.
    var code
    if (specialProperties.includes(dropdown_screen_properties)) {
        code = 'this.state["' + dropdown_set_screen_options + '"].' + dropdown_screen_properties;
    } else {
        code = 'this.state["' + dropdown_set_screen_options + '"].style.' + dropdown_screen_properties;
    }
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, null];
};

Blockly.JavaScript['al_screen_setter'] = function (block) {
    var dropdown_set_screen_options = block.getFieldValue('current_screen_input');
    var dropdown_screen_properties = block.getFieldValue('screen_properties');
    var value_set_screen = Blockly.JavaScript.valueToCode(block, 'set screen', null) || "\"\"";
    if (!value_set_screen.match('state')) {
        value_set_screen = "\"" + value_set_screen + "\""
    }
    // TODO: Assemble JavaScript into code variable.
    var code = ``
    if (specialProperties.includes(dropdown_screen_properties)) {
        code = `await this.setState({ 
    ...this.state,
    "` + dropdown_set_screen_options + `": {
        ...this.state["` + dropdown_set_screen_options + `"],
        "` + dropdown_screen_properties + `": ` + value_set_screen + `
    }
});
`;
    } else {
        code = `await this.setState({ 
    ...this.state,
    "` + dropdown_set_screen_options + `": {
        ...this.state["` + dropdown_set_screen_options + `"],
        style: {
            ...this.state["` + dropdown_set_screen_options + `"].style,
            "` + dropdown_screen_properties + `": ` + value_set_screen + `
        }
    }
});
`;
    }
    // TODO: Change ORDER_NONE to the correct strength.
    return code;
};

Blockly.JavaScript['al_move_screen'] = function (block) {
    var dropdown_screen_options = block.getFieldValue('screen_input');
    // TODO: Assemble JavaScript into code variable.
    var code = 'this.props.navigation.navigate("' + dropdown_screen_options + '");';
    return code;
};

Blockly.JavaScript['when_do_buttons'] = function (block) {
    var dropdown_component_input = block.getFieldValue('button_input');
    var dropdown_action_block = block.getFieldValue('action_options');
    var statements_do = Blockly.JavaScript.statementToCode(block, 'do');
    // TODO: Assemble JavaScript into code variable.
    var code
    if (dropdown_action_block === 'press') {
        code = `on` + dropdown_component_input + `Click = async () => {` + statements_do + `};
`;
    } else if (dropdown_action_block === 'longPress') {
        code = `on` + dropdown_component_input + `LongPress = async () => {` + statements_do + `};
`;
    }
    return code;
}

Blockly.JavaScript['al_button_getter'] = function (block) {
    var dropdown_component_input = block.getFieldValue('button_input');
    var dropdown_button_properties = block.getFieldValue('button_properties');
    // TODO: Assemble JavaScript into code variable.
    var code
    if (specialProperties.includes(dropdown_button_properties)) {
        code = 'this.state["' + dropdown_component_input + '"].' + dropdown_button_properties;
    } else {
        code = 'this.state["' + dropdown_component_input + '"].style.' + dropdown_button_properties;
    }
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, null];
};

Blockly.JavaScript['al_button_setter'] = function (block) {
    var dropdown_component_input = block.getFieldValue('button_input');
    // var dropdown_component_type = block.getFieldValue('component_type');
    var dropdown_button_properties = block.getFieldValue('button_properties');
    var value_set_button = Blockly.JavaScript.valueToCode(block, 'set button', null) || "\"\"";
    if (!value_set_button.match('state')) {
        value_set_button = "\"" + value_set_button + "\""
    }
    // TODO: Assemble JavaScript into code variable.
    // var code = dropdown_component_input + '.' + dropdown_component_type + ' = \'' + value_set_button + '\';';
    var code = ``
    if (specialProperties.includes(dropdown_button_properties)) {
        code = `await this.setState({ 
    ...this.state,
    "` + dropdown_component_input + `": {
        ...this.state["` + dropdown_component_input + `"],
        "` + dropdown_button_properties + `": ` + value_set_button + `
    }
});
`;
    } else {
        code = `await this.setState({ 
    ...this.state,
    "` + dropdown_component_input + `": {
        ...this.state["` + dropdown_component_input + `"],
        style: {
            ...this.state["` + dropdown_component_input + `"].style,
            "` + dropdown_button_properties + `": ` + value_set_button + `
        }
    }
});
`;
    }
    return code;
};

Blockly.JavaScript['al_label_getter'] = function (block) {
    var dropdown_set_label_options = block.getFieldValue('label_input');
    var dropdown_label_properties = block.getFieldValue('label_properties');
    // TODO: Assemble JavaScript into code variable.
    var code
    if (specialProperties.includes(dropdown_label_properties)) {
        code = 'this.state["' + dropdown_set_label_options + '"].' + dropdown_label_properties;
    } else {
        code = 'this.state["' + dropdown_set_label_options + '"].style.' + dropdown_label_properties;
    }
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, null];
};

Blockly.JavaScript['al_label_setter'] = function (block) {
    var dropdown_set_label_options = block.getFieldValue('label_input');
    var dropdown_label_properties = block.getFieldValue('label_properties');
    var value_set_label = Blockly.JavaScript.valueToCode(block, 'set label', null) || "\"\"";
    if (!value_set_label.match('state')) {
        value_set_label = "\"" + value_set_label + "\""
    }
    // TODO: Assemble JavaScript into code variable.
    var code = ``
    if (specialProperties.includes(dropdown_label_properties)) {
        code = `await this.setState({ 
    ...this.state,
    "` + dropdown_set_label_options + `": {
        ...this.state["` + dropdown_set_label_options + `"],
        "` + dropdown_label_properties + `": ` + value_set_label + `
    }
});
`;
    } else {
        code = `await this.setState({ 
    ...this.state,
    "` + dropdown_set_label_options + `": {
        ...this.state["` + dropdown_set_label_options + `"],
        style: {
            ...this.state["` + dropdown_set_label_options + `"].style,
            "` + dropdown_label_properties + `": ` + value_set_label + `
        }
    }
});
`;
    }
    return code;
};

Blockly.JavaScript['al_text_input_getter'] = function (block) {
    var dropdown_set_text_input_options = block.getFieldValue('textInput_input');
    var dropdown_text_input_properties = block.getFieldValue('text_input_properties');
    // TODO: Assemble JavaScript into code variable.
    var code
    if (specialProperties.includes(dropdown_text_input_properties)) {
        code = 'this.state["' + dropdown_set_text_input_options + '"].' + dropdown_text_input_properties;
    } else {
        code = 'this.state["' + dropdown_set_text_input_options + '"].style.' + dropdown_text_input_properties;
    }
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, null];
};

Blockly.JavaScript['al_text_input_setter'] = function (block) {
    var dropdown_set_text_input_options = block.getFieldValue('textInput_input');
    var dropdown_text_input_properties = block.getFieldValue('text_input_properties');
    var value_set_text_input = Blockly.JavaScript.valueToCode(block, 'set text input', null) || "\"\"";
    if (!value_set_text_input.match('state')) {
        value_set_text_input = "\"" + value_set_text_input + "\""
    }
    // TODO: Assemble JavaScript into code variable.
    var code = ``
    if (specialProperties.includes(dropdown_text_input_properties)) {
        code = `await this.setState({ 
    ...this.state,
    "` + dropdown_set_text_input_options + `": {
        ...this.state["` + dropdown_set_text_input_options + `"],
        "` + dropdown_text_input_properties + `": ` + value_set_text_input + `
    }
});
`;
    } else {
        code = `await this.setState({ 
    ...this.state,
    "` + dropdown_set_text_input_options + `": {
        ...this.state["` + dropdown_set_text_input_options + `"],
        style: {
            ...this.state["` + dropdown_set_text_input_options + `"].style,
            "` + dropdown_text_input_properties + `": ` + value_set_text_input + `
        }
    }
});
`;
    }
    return code;
};

Blockly.JavaScript['al_image_getter'] = function (block) {
    var dropdown_set_image_input_options = block.getFieldValue('image_input');
    var dropdown_image_properties = block.getFieldValue('image_properties');
    // TODO: Assemble JavaScript into code variable.
    var code
    if (specialProperties.includes(dropdown_image_properties)) {
        code = 'this.state["' + dropdown_set_image_input_options + '"].' + dropdown_image_properties;
    } else {
        code = 'this.state["' + dropdown_set_image_input_options + '"].style.' + dropdown_image_properties;
    }
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, null];
};

Blockly.JavaScript['al_image_setter'] = function (block) {
    var dropdown_set_image_input_options = block.getFieldValue('image_input');
    var dropdown_image_properties = block.getFieldValue('image_properties');
    var value_set_image = Blockly.JavaScript.valueToCode(block, 'set image', null) || "\"\"";
    if (!value_set_image.match('state')) {
        value_set_image = "\"" + value_set_image + "\""
    }
    // TODO: Assemble JavaScript into code variable.
    var code = ``
    if (specialProperties.includes(dropdown_image_properties)) {
        code = `await this.setState({ 
    ...this.state,
    "` + dropdown_set_image_input_options + `": {
        ...this.state["` + dropdown_set_image_input_options + `"],
        "` + dropdown_image_properties + `": ` + value_set_image + `
    }
});
`;
    } else {
        code = `await this.setState({ 
    ...this.state,
    "` + dropdown_set_image_input_options + `": {
        ...this.state["` + dropdown_set_image_input_options + `"],
        style: {
            ...this.state["` + dropdown_set_image_input_options + `"].style,
            "` + dropdown_image_properties + `": ` + value_set_image + `
        }
    }
});
`;
    }
    return code;
};

Blockly.JavaScript['set_color'] = function (block) {
    var get_color = block.getFieldValue('COLOR');
    // TODO: Assemble JavaScript into code variable.
    var code = get_color;
    return [code, null];
};

Blockly.JavaScript['set_text_input'] = function (block) {
    var text_name = block.getFieldValue('NAME');
    // TODO: Assemble JavaScript into code variable.
    var code = text_name
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, null];
};

Blockly.JavaScript['al_numerical_input'] = function (block) {
    var number_number_input = block.getFieldValue('number_input');
    // TODO: Assemble JavaScript into code variable.
    var code = number_number_input
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, null];
};

Blockly.JavaScript['al_boolean'] = function (block) {
    var dropdown_boolean = block.getFieldValue('boolean');
    // TODO: Assemble JavaScript into code variable.
    var code = dropdown_boolean
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, null];
};

Blockly.JavaScript['al_keyboard_type_input'] = function (block) {
    var dropdown_keyboard_type_options = block.getFieldValue('keyboard_type_options');
    // TODO: Assemble JavaScript into code variable.
    var code = dropdown_keyboard_type_options
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, null];
};

Blockly.JavaScript['al_screen_justification'] = function (block) {
    var dropdown_justification_options = block.getFieldValue('justification_options');
    // TODO: Assemble JavaScript into code variable.
    var code = dropdown_justification_options
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, null];
};

Blockly.JavaScript['al_screen_alignment'] = function (block) {
    var dropdown_alignment_options = block.getFieldValue('alignment_options');
    // TODO: Assemble JavaScript into code variable.
    var code = dropdown_alignment_options
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, null];
};

Blockly.JavaScript['al_insert_table_row'] = function (block) {
    return null
}

Blockly.JavaScript['al_get_table_row'] = function (block) {
    return null
}

Blockly.JavaScript['al_where_row_column'] = function (block) {
    return null
}

Blockly.JavaScript['al_next_row'] = function (block) {
    return null
}

Blockly.JavaScript['al_column_name'] = function (block) {
    return null
}


// Blockly.JavaScript['al_when_screen_do_components'] = function (block) {
//     var dropdown_component_input = block.getFieldValue('component_input');
//     var dropdown_action_block = block.getFieldValue('action_block');
//     var statements_do = Blockly.JavaScript.statementToCode(block, 'do');
//     // TODO: Assemble JavaScript into code variable.
//     var code = '...;\n';
//     return code;
// };

// Blockly.JavaScript['al_button_when_do'] = function (block) {
//     var dropdown_button_elements = block.getFieldValue('button_elements');
//     var dropdown_action_options = block.getFieldValue('action_options');
//     var statements_do = Blockly.JavaScript.statementToCode(block, 'do');
//     // TODO: Assemble JavaScript into code variable.
//     var code = '...;\n';
//     return code;
// };

// Blockly.JavaScript['al_listviewer_do'] = function (block) {
//     var dropdown_list_viewer_options = block.getFieldValue('list_viewer_options');
//     var dropdown_action_options = block.getFieldValue('action_options');
//     var value_item_input = Blockly.JavaScript.valueToCode(block, 'item_input', null);
//     var value_index_input = Blockly.JavaScript.valueToCode(block, 'index_input', null);
//     var statements_do = Blockly.JavaScript.statementToCode(block, 'do');
//     // TODO: Assemble JavaScript into code variable.
//     var code = '...;\n';
//     return code;
// };

// Blockly.JavaScript['al_list_viewer_setter'] = function (block) {
//     var dropdown_set_list_viewer_options = block.getFieldValue('set_list_viewer options');
//     var dropdown_text_input_properties = block.getFieldValue('text_input_properties');
//     var value_set_list_viewer = Blockly.JavaScript.valueToCode(block, 'set list_viewer', null);
//     // TODO: Assemble JavaScript into code variable.
//     var code = '...;\n';
//     return code;
// };

// Blockly.JavaScript['al_list_viewer_getter'] = function (block) {
//     var dropdown_get_list_viewer_options = block.getFieldValue('get_list_viewer options');
//     var dropdown_list_viewer_properties = block.getFieldValue('list_viewer_properties');
//     // TODO: Assemble JavaScript into code variable.
//     var code = '...';
//     // TODO: Change ORDER_NONE to the correct strength.
//     return [code, null];
// };



// Blockly.JavaScript['al_row_setter'] = function (block) {
//     var dropdown_set_row_options = block.getFieldValue('set_row options');
//     var dropdown_screen_properties = block.getFieldValue('screen_properties');
//     var value_set_row = Blockly.JavaScript.valueToCode(block, 'set row', null);
//     // TODO: Assemble JavaScript into code variable.
//     var code = '...;\n';
//     return code;
// };

// Blockly.JavaScript['al_row_getter'] = function (block) {
//     var dropdown_set_row_options = block.getFieldValue('set_row options');
//     var dropdown_row_properties = block.getFieldValue('row_properties');
//     // TODO: Assemble JavaScript into code variable.
//     var code = '...';
//     // TODO: Change ORDER_NONE to the correct strength.
//     return [code, null];
// };

// Blockly.JavaScript['al_image_input'] = function (block) {
//     var dropdown_image_options = block.getFieldValue('image_options');
//     // TODO: Assemble JavaScript into code variable.
//     var code = '...';
//     // TODO: Change ORDER_NONE to the correct strength.
//     return [code, null];
// };

// Blockly.JavaScript['al_list_viewer_item'] = function (block) {
//     // TODO: Assemble JavaScript into code variable.
//     var code = '...';
//     // TODO: Change ORDER_NONE to the correct strength.
//     return [code, null];
// };

// Blockly.JavaScript['al_list_viewer_index'] = function (block) {
//     // TODO: Assemble JavaScript into code variable.
//     var code = '...';
//     // TODO: Change ORDER_NONE to the correct strength.
//     return [code, null];
// };

// Blockly.JavaScript['al_list_viewer_list'] = function (block) {
//     // TODO: Assemble JavaScript into code variable.
//     var code = '...';
//     // TODO: Change ORDER_NONE to the correct strength.
//     return [code, null];
// };

// Blockly.JavaScript['al_screen_bg_resize_mode'] = function (block) {
//     var dropdown_reside_mode_options = block.getFieldValue('reside_mode_options');
//     // TODO: Assemble JavaScript into code variable.
//     var code = '...';
//     // TODO: Change ORDER_NONE to the correct strength.
//     return [code, null];
// };