import React from 'react';
import { FirebaseContext } from '../../components/firebase'
// Component
import ProjectList from './ProjectList'

const ProjectListScreen = () => {
    return <FirebaseContext.Consumer>
        {firebase => <ProjectList firebase={firebase} />}
    </FirebaseContext.Consumer>
}

export default ProjectListScreen