import { removeIn } from 'immutable';
import * as actionTypes from '../actionTypes'

const firstState = {
  projects: {},
  projectLoading: false,
  projectError: null,
  deleteLoading: false,
  deleteError: null,
  editLoading: false,
  editError: null,
  addLoading: false,
  addError: null,
  changeScreenLoading: false,
  changeScreenError: null,
}

export default (state = firstState, action) => {
  switch (action.type) {
    case actionTypes.EDIT_PROJECT:
      return {
        ...state,
        editLoading: true,
      }
    case actionTypes.EDIT_PROJECT_SUCCESS:
      let editProject = state.projects[action.payload.pid]
      Object.keys(action.payload)
        .filter(key => key !== 'pid')
        .map(key => editProject[key] = action.payload[key])
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.payload.pid]: editProject,
        },
        editLoading: false,
        editError: null
      }
    case actionTypes.EDIT_PROJECT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.error,
      }
    case actionTypes.ADD_PROJECT:
      return {
        ...state,
        addLoading: true,
      }
    case actionTypes.ADD_PROJECT_SUCCESS:
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.payload._id]: action.payload,
        },
        addLoading: false,
        addError: null
      }
    case actionTypes.ADD_PROJECT_FAILURE:
      return {
        ...state,
        addError: action.error,
        addLoading: false,
      }
    case actionTypes.FETCH_PROJECTS:
      return {
        ...state,
        projectLoading: true,
      };
    case actionTypes.FETCH_PROJECTS_SUCCESS:
      let projects = {}
      action.payload.map(projid => projects[projid._id] = projid)
      return {
        ...state,
        projectLoading: false,
        projects: projects,
        projectError: null,
      }
    case actionTypes.FETCH_PROJECTS_FAILURE:
      return {
        ...state,
        projectLoading: false,
        projectError: action.error
      }
    case actionTypes.FETCH_ONE_PROJECT:
      return {
        ...state,
        projectLoading: true,
      }
    case actionTypes.FETCH_ONE_PROJECT_SUCCESS:
      let oneProject = {}
      oneProject[action.payload._id] = action.payload
      return {
        ...state,
        projectLoading: false,
        projects: oneProject,
        projectError: null,
      }
    case actionTypes.FETCH_ONE_PROJECT_FAILURE:
      return {
        ...state,
        projectLoading: false,
        projectError: action.error,
      }
    case actionTypes.DELETE_PROJECT:
      return {
        ...state,
        deleteLoading: true,
      }
    case actionTypes.DELETE_PROJECT_SUCCESS:
      const new_state = removeIn(state.projects, [action.pid])
      return {
        ...state,
        projects: new_state,
        deleteLoading: false,
        deleteError: null,
      };
    case actionTypes.DELETE_PROJECT_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteError: action.error
      }
    case actionTypes.DELETE_SCREEN:
      return {
        ...state,
        editProject: true,
      }
    case actionTypes.DELETE_SCREEN_SUCCESS:
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.pid]: {
            ...state.projects[action.pid],
            current_screen: state.projects[action.pid].root_screen
          }
        },
        editProject: false
      }
    default:
      return state;
  }
};
