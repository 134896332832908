import React from 'react';
// Components
import Backdrop from '../backdrop/backdrop';
// Styles
import styles from './modal.module.css';

export const Modal = props => {
    return (
        <div className={styles.modalContainer}>
            <Backdrop onClose={props.no} />
            <div className={styles.modalDesign}>
                <div style={{
                    transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
                    opacity: props.show ? '1' : '0',
                }}
                    className={styles.Modal}>
                    {props.children}
                    <div className={styles.ModalHeader} onClick={props.no}>
                        <span>&times;</span>
                    </div>
                </div>
            </div>
        </div>
    )
}