import React from 'react'
// Styles
import styles from './singleComponent.module.css'
// Static

export const SingleComponent = props => {
    if (props.caret) {
        return <li
            draggable
            data-comptype="advanced"
            data-id={props.id}
            data-parent={props.parent}
            onDragLeaveCapture={props.dragLeave}
            onDragOverCapture={props.dragOver}
            onDragStartCapture={props.dragStart}
            onDragEnd={props.dragEnd}
            onDropCapture={props.drop}
            className={props.selected ? [styles.selected, styles.singleComponentList].join(' ') : styles.singleComponentList}
            onClickCapture={() => props.onSelect(props.id)}>
            <label
                data-comptype="advanced"
                data-id={props.id}
                data-parent={props.parent}
                className={styles.singleComponentNameLabel}>
                {props.name}
            </label>
            {props.children}
        </li>
    }
    else {
        return <li
            draggable={!!props.dragStart}
            data-comptype="simple"
            data-id={props.id}
            data-parent={props.parent}
            onDragLeave={props.dragLeave}
            onDragOver={props.dragOver}
            onDragStart={props.dragStart}
            onDragEnd={props.dragEnd}
            onDrop={props.drop}
            className={props.selected ? [styles.selected, styles.singleComponentList].join(' ') : styles.singleComponentList}
            onClickCapture={() => props.onSelect(props.id)}>
            <label
                data-comptype="simple"
                data-id={props.id}
                data-parent={props.parent}
                className={styles.singleComponentNameLabel}>
                {props.name}
            </label>
        </li>
    }
}