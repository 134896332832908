import React from 'react'
// Component
// Constants
import { k_buttonid, k_labelid, k_textInputid, k_imageid, k_videoid, k_rowid, k_columnid, k_dataid, k_switchid, k_dateTimeid, k_iconid, k_radioButtonid } from '../../constants'
// static files
import styles from './metaComponent.module.css'
import button from '../../static/images/button.svg'
import label from '../../static/images/label.svg'
import textBox from '../../static/images/password.svg'
import imgSvg from '../../static/images/image.svg'
import vidSvg from '../../static/images/video.svg'
import row from '../../static/images/row.svg'
import column from '../../static/images/column.svg'
import data from '../../static/images/tableDB.svg'
import dateTime from '../../static/images/dateTimeBlack.svg'
import switchIcon from '../../static/images/switch.svg'
import icon from '../../static/images/iconBlack.svg'
import radio from '../../static/images/radioButtonBlack.svg'

const dragStartHandler = e => {
    e.dataTransfer.effectAllowed = 'copy'
    e.dataTransfer.setData('type', e.target.getAttribute('data-type'))
    // console.log(e.dataTransfer)
    return true
}

export const metaComponent = props => {
    let image = null
    let helperText = null
    switch (props.id) {
        case k_buttonid:
            image = <img src={button} alt="button" />
            break
        case k_labelid:
            image = <img src={label} alt="label" />
            break
        case k_textInputid:
            image = <img src={textBox} alt="textbox" />
            break
        case k_imageid:
            image = <img src={imgSvg} alt="" />
            break
        case k_videoid:
            image = <img src={vidSvg} alt="video" />
            break;
        case k_rowid:
            image = <img src={row} alt="row" />
            helperText = 'Place components next to each other'
            break
        case k_columnid:
            image = <img src={column} alt="column" />
            helperText = 'Place components above and below each other'
            break
        case k_dataid:
            image = <img src={data} alt="data" />
            break;
        case k_switchid:
            image = <img src={switchIcon} alt="switch" />
            break;
        case k_dateTimeid:
            image = <img src={dateTime} alt="Date time input" />
            break;
        case k_iconid:
            image = <img src={icon} alt="Icon" />
            break;
        case k_radioButtonid:
            image = <img src={radio} alt="Radio button" />
            break;
        default:
            break
    }

    return (
        <div
            className={styles.metaComponent}
            data-type={props.c_type}
            onDragStart={dragStartHandler}
            draggable>
            {image}{props.name}
            {!!helperText
                && <i className="fa fa-info" title={helperText} />}
        </div>
    )
}