import React from 'react'
// Styles
import styles from './leftSideNav.module.css'
// Components
// static
import profile from '../../../static/images/profileBlue.svg'
import mazeLab from '../../../static/images/mazeLabBlue.svg'
import gameLab from '../../../static/images/gameLabBlue.svg'
import storyLab from '../../../static/images/storyLabBlue.svg'
import appLab from '../../../static/images/appLabBlue.svg'
import itklogo from '../../../static/images/itklogo.svg'
// import gallery from '../../../static/images/galleryBlue.svg'
import logout from '../../../static/images/logoutBlue.svg'

const onAfterSchoolClick = (lab) => {
    window.location.replace(process.env.REACT_APP_AFTERSCHOOL + lab)
}

const onLogout = () => {
    window.location.replace(process.env.REACT_APP_AUTH + 'logout')
}

export const leftSideNav = (props) => {
    return (
        <div className={styles.container}>
            <div className={styles.navBar}>
                <img src={itklogo} className={styles.itkLogo} alt="ITK Logo" />
                <div style={{ display: "flex", width: "100%" }}>
                    <div className={styles.fixedListItems}>
                        <li className={styles.profile} onClick={() => onAfterSchoolClick('profile')}><img src={profile} alt="Profile" /><label>Profile</label></li>
                        <li onClick={() => onAfterSchoolClick('maze')}><img src={mazeLab} alt="Maze" /><label>Maze Lab</label></li>
                        <li onClick={() => onAfterSchoolClick('story')}><img src={storyLab} alt="Story" /><label>Story Lab</label></li>
                        <li onClick={() => onAfterSchoolClick('game')}><img src={gameLab} alt="Game" /><label>Game Lab</label></li>
                        <li className={props.applab ? styles.selected : null}><img src={appLab} alt="App" /><label>App Lab</label></li>
                        <li onClick={onLogout}><img src={logout} alt="logout" /><label>Logout</label></li>
                        {/* <li className={styles.gallery}><img src={gallery} alt="Gallery" /><label>Gallery</label></li> */}
                    </div>
                    <div style={{ width: "10%" }}></div>
                </div>
            </div>
        </div>
    )
}