import React from 'react'
// styles
import styles from './advancedComponent.module.css'
// Constants
import { k_rowid, k_columnid } from '../../../constants'

export const advancedComponent = (props) => {
    let personalStyle = {}

    personalStyle = {}
    if (typeof props.properties[Object.keys(props.properties)[0]] === 'object') {
        Object.keys(props.properties).map(propertyName => {
            personalStyle[propertyName] = props.properties[propertyName].value
            if (props.type === k_rowid || props.type === k_columnid) {
                if ((propertyName === 'borderStyle' && props.properties[propertyName].value === 'none')
                    || (propertyName === 'borderWidth' && props.properties[propertyName].value === '0px')
                    || (propertyName === 'borderColor' && props.properties[propertyName].value === 'none')) {
                    personalStyle['borderStyle'] = 'dashed'
                    personalStyle['borderWidth'] = '1px'
                    personalStyle['borderColor'] = 'lightgray'
                }
            }
            return null
        })
        Object.keys(props.styles).map(styleName => {
            personalStyle[styleName] = props.styles[styleName]
            return null
        })
    } else {
        personalStyle = props.properties
    }

    if (props.selected) {
        switch (props.type) {
            case k_rowid:
                return <div
                    className={styles.row}
                    data-id={props.dataId}
                    data-parent={props.dataParent}
                    data-comptype="advanced"
                    style={personalStyle}
                    draggable
                    onDragStart={props.dragStart}
                    onDragOver={props.dragOver}
                    onDragLeave={props.dragLeave}
                    onDrop={props.drop}>
                    <div
                        data-id={props.dataId}
                        data-parent={props.dataParent}
                        data-comptype="advanced"
                        className={styles.selected}></div>
                    {props.children}
                </div>
            case k_columnid:
                return <div
                    data-id={props.dataId}
                    data-parent={props.dataParent}
                    data-comptype="advanced"
                    draggable
                    style={personalStyle}
                    className={styles.column}
                    onDragStart={props.dragStart}
                    onDragOver={props.dragOver}
                    onDragLeave={props.dragLeave}
                    onDrop={props.drop}>
                    <div
                        data-id={props.dataId}
                        data-parent={props.dataParent}
                        data-comptype="advanced"
                        className={styles.selected}></div>
                    {props.children}
                </div >
            default:
                return null
        }
    }
    else {
        switch (props.type) {
            case k_rowid:
                return <div
                    className={styles.row}
                    style={personalStyle}
                    data-id={props.dataId}
                    data-parent={props.dataParent}
                    data-comptype="advanced"
                    draggable
                    onDragStart={props.dragStart}
                    onDragOver={props.dragOver}
                    onDragLeave={props.dragLeave}
                    onDrop={props.drop}
                    onClickCapture={() => props.onSelect(props.dataId)}>
                    {props.children}
                </div>
            case k_columnid:
                return <div
                    className={styles.column}
                    style={personalStyle}
                    data-id={props.dataId}
                    data-parent={props.dataParent}
                    data-comptype="advanced"
                    draggable
                    onDragStart={props.dragStart}
                    onDragOver={props.dragOver}
                    onDragLeave={props.dragLeave}
                    onDrop={props.drop}
                    onClickCapture={() => props.onSelect(props.dataId)}>
                    {props.children}
                </div>
            default:
                return null
        }
    }
}