import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// styles
import styles from './singleProject.module.css'
// static
import projectIcon from '../../static/images/addProjectBlue.svg'
import tableIcon from '../../static/images/addTableBlue.svg'
import deleteProject from '../../static/images/deleteBlue.svg'
import deleteProjectHover from '../../static/images/deleteYellow.svg'
import editIcon from '../../static/images/editBlue.svg'
import editIconHover from '../../static/images/editYellow.svg'
import dateTime from '../../static/images/dateTimeWhite.svg'

export const SingleProject = (props) => {

    const [show, setShow] = useState(false)

    return (
        <div className={styles.container}>
            <div className={styles.cardHeader}>
                <div className={styles.bubble}>
                    <img src={props.table ? tableIcon : projectIcon} alt="info" />
                </div>
                <div className={styles.editEllipsis} onClick={() => setShow(!show)}>
                    <span>&hellip;</span>
                </div>
            </div>
            <Link to={props.table ? "/table/" + props.id : "/project/" + props.id}>
                <h1>{props.name}</h1>
            </Link>
            <sub className={styles.description}>{props.description}</sub>
            <div className={styles.createdOn}>
                <img src={dateTime} alt="created_on" />
                <span>{new Date(props.createdOn).toDateString()}</span>
            </div>
            {show && <div className={styles.optionsTab}>
                <div className={styles.optionsTabContainer}>
                    <span onClick={() => setShow(!show)}>&hellip;</span>
                    <div className={styles.actionButton} onClick={() => props.edit(props.id)}>
                        <img src={editIcon} alt="edit" onClick={() => props.edit(props.id)}
                            onMouseOver={(e) => e.currentTarget.src = editIconHover}
                            onMouseLeave={(e) => e.currentTarget.src = editIcon} />
                        <span>Edit</span>
                    </div>
                    <div className={styles.actionButton} onClick={() => props.delete(props.id)}>
                        <img src={deleteProject} alt="del"
                            onMouseOver={(e) => e.currentTarget.src = deleteProjectHover}
                            onMouseLeave={(e) => e.currentTarget.src = deleteProject} />
                        <span>Delete</span>
                    </div>
                </div>
            </div>}
        </div >
    )
}