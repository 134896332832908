import React from 'react'
// Components
import Property from '../property/property'
import { PropertySubCategory } from '../propertySubCategory/propertySubCategory'
// Styles
import styles from './propertyCategory.module.css'

export const PropertyCategory = props => {
    let uniqueSubCategories = []
    let subCatProperties = []
    props.categoryProperties.map(property => {
        if ('subCategory' in property) {
            if (!uniqueSubCategories.includes(property.subCategory)) {
                uniqueSubCategories.push(property.subCategory)
            }
        }
        return null
    })
    return <details className={styles.details} open={true}>
        <summary className={styles.summary}>{props.categoryName}</summary>
        {props.categoryProperties.filter(propertyName => !('subCategory' in propertyName)).map(propertyName => {
            return <Property key={propertyName.name}
                id={props.id}
                name={propertyName.name}
                value={propertyName.value}
                cTypeId={props.cTypeId}
                edit={props.edit} />
        })}
        {Array.isArray(uniqueSubCategories) && uniqueSubCategories.length !== 0
            ? uniqueSubCategories.map(subCat => {
                subCatProperties = props.categoryProperties.filter(property => 'subCategory' in property && property.subCategory === subCat)
                return <PropertySubCategory
                    key={subCat}
                    subCategory={subCat}
                    properties={subCatProperties}
                    id={props.id}
                    cTypeId={props.cTypeId}
                    edit={props.edit} />
            })
            : null
        }
    </details>
}